import { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  retriveBrands,
  retriveSingleCategory,
} from "../../services/CategoryService";
import {
  addItemToCart,
  addItemToWishlist,
  retriveItems,
} from "../../services/ItemService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  convertToSlug,
  decodeHTMLEntities,
  isLogin,
} from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useRef } from "react";
import { getCartItemQty } from "../../services/CartService";
import Loader from "../Loader";
import { userId } from "../../layout/Index";
import _ from "lodash";
import Blink from "react-blink-text";
import qs from "qs";

const ProductCategory = () => {
  const [category, setCategory] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const params = useParams();
  const [page, setPage] = useState(params?.page);
  const [perPage, setPerPage] = useState(20);
  const [brands, setBrands] = useState([]);
  const [filter, setFilter] = useState("Select Filter");
  const [links, setLinks] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const { setCartQty, cart_qty, wishlist_qty, setWishlistQty } =
    useContext(AppContext);
  const [showAllBrands, setShowAllBrands] = useState(true);
  const [showSortByItemCount, setShowSortByItemCount] = useState(false);
  const [showSortByList, setShowSortByList] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const brandRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();


  const { selectedSubCat, selectedBrandIds } = qs?.parse(location?.search, {
    ignoreQueryPrefix: true,
  });

  const noPhotoFound =
    process.env.REACT_APP_BASE_URL + "/storage/products/no-image-product.png";

  const debouncedFetchResults = useCallback(
    _.debounce(
      (subCatSearch, brandsArray) => getItems(subCatSearch, brandsArray),
      300
    ),
    []
  );

  useEffect(() => {
    if (params.category) {
      setPage(params?.page);
      getSubcategories(params?.category);
      getBrands(params?.category);

      let brands = "";

      if (!_.isEmpty(selectedBrandIds)) {
        brands = selectedBrandIds?.join(",");
        setSelectedBrands(selectedBrandIds);
      } else {
        setSelectedBrands([]);
      }
      let subCatSearch = "";
      if (!_.isEmpty(selectedSubCat)) {
        subCatSearch = selectedSubCat?.join(",");
        setSelectedCategories(selectedSubCat);
        debouncedFetchResults(subCatSearch, brands);
      } else {
        setSelectedCategories([]);
        debouncedFetchResults(params?.category, brands);
      }
    }

    document.getElementById("all-categories")?.classList.remove("show");

    return () => {
      setSelectedBrands([]);
      setFilter("");
    };
  }, [params?.category, perPage]);

  useEffect(() => {
    if (!showAllBrands) {
      if (brandRef.current !== undefined) {
        brandRef.current.scrollTop = 0;
      }
    }
  }, [showAllBrands]);

  useEffect(() => {
    if (page != params?.page) {

      window.scrollTo(0, 0);
      let brands = "";
      if (selectedBrands.length > 0) {
        brands = selectedBrands.join(",");
      }
      const brandArray = selectedBrands;
      const queryStringBrand = qs?.stringify({
        selectedBrandIds: brandArray,
      });
      const myArray = selectedCategories;
      const queryString = qs?.stringify({
        selectedSubCat: myArray,
      });

      navigate(
        `/product-category/${params?.category}/${page}?${queryString}&${queryStringBrand}`,
        {
          replace: true,
        }
      );
      // debouncedFetchResults(myArray?.join(",") || params?.category, brands);
      getItems(myArray?.join(",") || params?.category, brands);
    } else if (!_.isEqual(selectedSubCat, selectedCategories)) {
      let brands = "";
      if (selectedBrands.length > 0) {
        brands = selectedBrands.join(",");
      }
      const brandArray = selectedBrands;
      const queryStringBrand = qs?.stringify({
        selectedBrandIds: brandArray,
      });
      const myArray = selectedCategories;
      const queryString = qs?.stringify({
        selectedSubCat: myArray,
      });
      setPage(1);
      navigate(
        `/product-category/${params?.category}/1?${queryString}&${queryStringBrand}`,
        {
          replace: true,
        }
      );
      debouncedFetchResults(
        myArray?.join(",") || params?.category,
        brandArray?.join(",") || brands
      );

      // getItems(myArray?.join(",") || params.category, brands);
    } else if (!_.isEqual(selectedBrandIds, selectedBrands)) {
      let brands = "";
      if (selectedBrands.length > 0) {
        brands = selectedBrands.join(",");
      }
      const myArray = selectedBrands;
      const queryStringBrand = qs?.stringify({
        selectedBrandIds: myArray,
      });

      const catArray = selectedCategories;
      const queryString = qs?.stringify({
        selectedSubCat: catArray,
      });
      setPage(1);

      navigate(
        `/product-category/${params?.category}/1?${queryString}&${queryStringBrand}`,
        {
          replace: true,
        }
      );
      debouncedFetchResults(
        catArray?.join(",") || params?.category,
        myArray?.join(",") || brands
      );

      // getItems(myArray?.join(",") || params.category, brands);
    }
  }, [page, selectedCategories, selectedBrands]);

  console.log(selectedCategories, "<<<");

  useEffect(() => {
    if (!_.isEmpty(location?.state?.isReload)) {
      setPage(params?.page);

      let brands = "";

      if (!_.isEmpty(selectedBrandIds)) {
        brands = selectedBrandIds?.join(",");
        setSelectedBrands(selectedBrandIds);
      } else {
        setSelectedBrands([]);
      }
      let subCatSearch = "";
      if (!_.isEmpty(selectedSubCat)) {
        subCatSearch = selectedSubCat?.join(",");
        setSelectedCategories(selectedSubCat);
        debouncedFetchResults(subCatSearch, brands);
      } else {
        setSelectedCategories([]);
        debouncedFetchResults(params?.category, brands);
      }
    }
  }, [location?.state?.isReload]);


  useEffect(() => {
    // asceding Order
    if (filter === "Price: Low to High") {
      const sorted_items = [...items].sort(
        (a, b) => a.sales_price - b.sales_price
      );
      setItems(sorted_items);
    }

    // Desceding Order
    if (filter == "Price: High to Low") {
      const sorted_items = [...items].sort(
        (a, b) => b.sales_price - a.sales_price
      );
      setItems(sorted_items);
    }

    // // Released Date Order
    // if (filter === "Release Date") {
    //   let date1 = "";
    //   let date2 = "";
    //   const sorted_items = [...items].sort((a, b) => {
    //     date1 = Date.parse(b.created_date.concat(" ", b.created_time));
    //     date2 = Date.parse(a.created_date.concat(" ", a.created_time));
    //     return date1 - date2;
    //   });
    //   setItems(sorted_items);
    // }
  }, [filter]);

  const getBrands = async (cat) => {
    await retriveBrands(cat)
      .then((response) => {
        if (response.data) {
          const array = sortArrayByAlphabetic(response.data.data);
          setBrands(array);
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };

  const getSubcategories = async (sub_cat) => {
    await retriveSingleCategory(sub_cat)
      .then((response) => {
        if (response.data.data) {
          const array = sortSubCategoryByAlphabetic(
            response.data.data.subcategory
          );
          setCategory({ ...response.data.data, subcategory: array });

          // let subcats = response.data.data.subcategory.map((cat) => cat.slug);
          // subcats.push(response.data.data.main_category.slug);
          // setSelectedCategories(subcats);
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };

  const manipuateItemData = (itemDetail) => {
    let mainArray = [];
    let groupArray = [];
    for (let a of itemDetail) {
      let obj = a;
      if (a?.is_group) {
        groupArray?.push({ ...obj, id: a?.group_master_id, isItem: false });
      } else {
        if (a?.cart === null) {
          obj = {
            ...a,
            cart: { item_id: a?.id, quantity: 0 },
          };
          mainArray?.push({ ...obj, isItem: true });
        } else {
          mainArray?.push({ ...obj, isItem: true });
        }
      }
    }

    return [...groupArray, ...mainArray];
  };

  const getItems = async (category, brands) => {
    setisLoading(true);
    let paramsData = {
      category: category || selectedCategories?.join(",") || params?.category,
      brands: brands,
      max_price: maxPrice,
      min_price: minPrice,
      per_page: perPage,
      page: page || params?.page,
    };


    await retriveItems(paramsData)
      .then((response) => {
        if (response.data.data) {
          let itemData = manipuateItemData(response?.data?.data?.data);
          setItems(itemData);
          // setItems([...data?.groupDetail?.data, ...itemData]);
          setisLoading(false);

          // setLinks(
          //   manipulateNavigationArray(response?.data?.data?.data?.links, [
          //     ...response.data?.data?.data?.itemDetail?.links,
          //   ])
          // );
          setLinks([
            // ...response.data?.data?.data?.groupDetail?.links,
            ...response?.data?.data?.links,
          ]);
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };

  const handleCategoryChange = (e) => {
    let { name, value } = e.target;

    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat != value));
      // manipulatedSubCategoryItemsBySelection(
      //   selectedCategories.filter((cat) => cat != value)
      // );
    } else {
      // manipulatedSubCategoryItemsBySelection([...selectedCategories, value]);
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const handleBrandChange = (brand_id) => {
    let value = brand_id;
    if (selectedBrands?.map(String)?.includes(String(value))) {
      // if (selectedBrands?.includes(value)) {
      setSelectedBrands(selectedBrands.filter((cat) => cat != value));
      // manipulatedBrandItemsBySelection(
      //   selectedBrands.filter((cat) => cat != value)
      // );
    } else {
      setSelectedBrands([...selectedBrands, value]);
      // manipulatedBrandItemsBySelection([...selectedBrands, value]);
    }
  };

  const sortArrayByAlphabetic = (array) => {
    array.sort(function (a, b) {
      if (a.brand_name < b.brand_name) {
        return -1;
      }
      if (a.brand_name > b.brand_name) {
        return 1;
      }
      return 0;
    });

    return array;
  };

  const sortSubCategoryByAlphabetic = (array) => {
    array.sort(function (a, b) {
      if (a?.category_name < b?.category_name) {
        return -1;
      }
      if (a?.category_name > b?.category_name) {
        return 1;
      }
      return 0;
    });

    return array;
  };

  const manipulatedBrandItemsBySelection = (selectedBrannumberArray) => {
    let selectenumberArray = [];
    let unselectenumberArray = [];
    for (let a of sortArrayByAlphabetic(brands)) {
      if (selectedBrannumberArray.includes(a?.id)) {
        selectenumberArray.push(a);
      } else {
        unselectenumberArray.push(a);
      }

      setBrands([...selectenumberArray, ...unselectenumberArray]);
    }
  };

  const wishlistToggle = async (item, e, isGroupToggle) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", item);
    formdata.append("is_group", isGroupToggle);

    await addItemToWishlist(formdata)
      .then((response) => {
        toast(response?.data?.message, { type: "success" });
        let data = [];
        for (let a of items) {
          let value = a;
          if (a?.id === item) {
            if (a?.wishlist) {
              setWishlistQty(wishlist_qty - 1);
            } else {
              setWishlistQty(wishlist_qty + 1);
            }
            value = { ...a, wishlist: a?.wishlist ? 0 : 1 };
          }
          data?.push(value);
        }
        setItems(data);
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handleAddToCart = (item, e) => {
    e.preventDefault();
    if (isLogin()) {
      let item_id = item.id;
      let quantity = item.cart.quantity;
      if (quantity <= 0) {
        // getAllItems();
        toast("Please Enter the quantity", { type: "error" });
        return;
      }
      addItemToCart(item_id, parseInt(quantity))
        .then((response) => {
          if (response.data.message == "no_stock") {
            toast("Item Stock Not Availbale.", { type: "error" });
          } else {
            CountCartQty();
            toast("Item Added To Cart Successfully.", { type: "success" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const handleCartQuantity = (index, value) => {
    let flag = false;
    if (!isNaN(value) && value >= 0) {
      if (items[index].stock >= value) {
        let _product = items;
        let _item = items[index].cart;
        _item["quantity"] = value;
        _product[index]["cart"] = _item;
        setItems([..._product]);
        flag = true;
      } else if (items[index].stock <= 0) {
        toast("Out Of Stock", { type: "error" });
      } else {
        toast(
          `Please Enter proper quantity,there are only ${items[index]?.stock} stock left.`,
          { type: "error" }
        );
      }
    } else {
      flag = true;
      toast("Please Enter the quantity", { type: "error" });
    }
    if (!flag) {
      let _product = items;
      let _item = items[index].cart;
      _item["quantity"] = 0;
      _product[index]["cart"] = _item;
      setItems([..._product]);
    }
  };

  const CountCartQty = () => {
    getCartItemQty()
      .then((response) => {
        setCartQty(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handlePriceFilter = () => {
    /*  if(minPrice <= 0){
         toast('Invalid minimum price in filter',{type:'error'});
         return false;
     }
     if(minPrice > maxPrice){
         toast('Maximum price should be greater than or equal to minimum price.',{type:'error'});
         return false;
     } */
    let categories = params.category;
    if (selectedCategories.length > 0) {
      categories = selectedCategories.join(",");
    }
    let brands = "";
    if (selectedBrands.length > 0) {
      brands = selectedBrands.join(",");
    }
    getItems(categories, brands);
  };

  const setPerPageValue = (e, count) => {
    e.preventDefault();
    setPerPage(count);
    setShowSortByItemCount(false);
  };

  const setFilterValue = (e, value) => {
    e.preventDefault();
    setFilter(value);
    setShowSortByList(false);
  };

  const handleMainCategoryChange = (e) => {
    if (e?.target?.checked) {
      // setSelectedCategories([
      //   ...selectedCategories,
      //   category.main_category.slug,
      // ]);
      let subcats = category?.subcategory?.map((cat) => cat.slug);
      subcats.push(category.main_category.slug);
      setSelectedCategories(subcats);
    } else {
      let temp = selectedCategories.filter(
        (cat_slug, index) => cat_slug != category.main_category.slug
      );
      setSelectedCategories([]);
    }
  };

  const manipulatedSubCategoryItemsBySelection = (array, value) => {
    let selectenumberArray = [];
    let unselectenumberArray = [];
    for (let a of sortSubCategoryByAlphabetic(category?.subcategory)) {
      if (array.includes(a?.slug)) {
        selectenumberArray.push(a);
      } else {
        unselectenumberArray.push(a);
      }
      setCategory({
        ...category,
        subcategory: [...selectenumberArray, ...unselectenumberArray],
      });
    }
  };

  return (
    <main className="main" style={{ transform: "none" }}>
      <div className="page-header mt-30 mb-50">
        <div className="container">
          <div className="archive-header">
            <div
              className="row align-items-center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="col-xl-3">
                <h1
                  className="mb-15 product-category-name"
                  style={{ width: "max-content" }}
                >
                  {decodeHTMLEntities(category?.main_category?.category_name)}
                </h1>
                <div className="breadcrumb">
                  <Link to="/" rel="nofollow">
                    <i className="fi-rs-home mr-5" />
                    Home
                  </Link>
                  <span />
                  {decodeHTMLEntities(category?.main_category?.category_name)}

                  <span />
                </div>
              </div>
              {/* <div className="col-xl-6 text-end mt-10 d-xl-block">
                <ul
                  className="tags-list"
                  style={{ height: "120px", overflow: "scroll" }}
                >
                  {selectedBrands?.map((brand, index) => {
                    return (
                      <li className="hover-up m-2" key={index}>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          Brand:{" "}
                          {brands.map((_b) => {
                            if (_b.id == brand) {
                              return _b.brand_name;
                            }
                          })}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-30" style={{ transform: "none" }}>
        <div className="row flex-row-reverse" style={{ transform: "none" }}>
          <div className="col-lg-4-5">
            <div className="shop-product-fillter">
              <div className="totall-product">
                <p>
                  We found{" "}
                  <strong className="text-brand">{items.length}</strong> items
                  for you!
                </p>
              </div>
              <div className="sort-by-product-area">
                <div className="sort-by-cover mr-10">
                  <div
                    className="sort-by-product-wrap"
                    onClick={() => setShowSortByItemCount(!showSortByItemCount)}
                  >
                    <div className="sort-by">
                      <span>
                        <i className="fi-rs-apps" />
                        Show:
                      </span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span>
                        {" "}
                        {perPage} <i className="fi-rs-angle-small-down" />
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      "sort-by-dropdown" + (showSortByItemCount ? " show" : "")
                    }
                  >
                    <ul>
                      <li>
                        <a
                          className={perPage == 10 ? "active" : ""}
                          onClick={(e) => setPerPageValue(e, 10)}
                          href=""
                        >
                          10
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className={perPage == 20 ? "active" : ""}
                          onClick={(e) => setPerPageValue(e, 20)}
                        >
                          20
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className={perPage == 30 ? "active" : ""}
                          onClick={(e) => setPerPageValue(e, 30)}
                        >
                          30
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className={perPage == 40 ? "active" : ""}
                          onClick={(e) => setPerPageValue(e, 40)}
                        >
                          40
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className={perPage == 50 ? "active" : ""}
                          onClick={(e) => setPerPageValue(e, 50)}
                        >
                          50
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sort-by-cover">
                  <div
                    className="sort-by-product-wrap"
                    onClick={() => setShowSortByList(!showSortByList)}
                  >
                    <div className="sort-by">
                      <span>
                        <i className="fi-rs-apps-sort" />
                        Sort by:
                      </span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span>
                        {" "}
                        {filter} <i className="fi-rs-angle-small-down" />
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      "sort-by-dropdown" + (showSortByList ? " show" : "")
                    }
                  >
                    <ul>
                      <li>
                        <a
                          className={filter === "Select Filter" ? "active" : ""}
                          onClick={(e) => setFilterValue(e, "")}
                        >
                          Select Filter
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            filter === "Price: Low to High" ? "active" : ""
                          }
                          onClick={(e) =>
                            setFilterValue(e, "Price: Low to High")
                          }
                        >
                          Price: Low to High
                        </a>
                      </li>
                      <li>
                        <a
                          className={
                            filter === "Price: High to Low" ? "active" : ""
                          }
                          onClick={(e) =>
                            setFilterValue(e, "Price: High to Low")
                          }
                        >
                          Price: High to Low
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href=""
                          className={filter == "Release Date" ? "active" : ""}
                          onClick={(e) => setFilterValue(e, "Release Date")}
                        >
                          Release Date
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <div className="row product-grid-4 m-xl-5 m-lg-3 m-sm-0">
                {items?.map((item, index) => {
                  let imagePath;
                  if (item?.isItem) {
                    if (item?.item_image) {
                      imagePath =
                        process.env.REACT_APP_BASE_URL +
                        "/storage/products/" +
                        item?.image;
                    }
                  } else {
                    if (item?.groups_image) {
                      imagePath =
                        process.env.REACT_APP_BASE_URL +
                        "/storage/group_images/" +
                        item?.groups_image;
                    } else if (item?.new_groups_image) {
                      imagePath =
                        process.env.REACT_APP_BASE_URL +
                        "/storage/products/" +
                        item?.new_groups_image;
                    }
                  }

                  let element = document?.getElementById(
                    `noRightClick${index}`
                  );
                  element?.addEventListener("contextmenu", (ev) => {
                    ev?.preventDefault(); // this will prevent browser default behavior
                  });

                  let isItem = item?.isItem ? true : false;
                  return (
                    <div
                      key={index}
                      className="col-lg-1-4 col-md-4 col-12 col-sm-5 inner-product-grid"
                    >
                      <div
                        className="product-cart-wrap mb-30"
                        // id="hover-area"
                        style={{
                          borderColor: "var(--primary-color)",
                          minHeight: "400px",
                        }}
                      >
                        <div
                          className="product-img-action-wrap"
                          id={`noRightClick${index}`}
                        >
                          <div className="product-img product-img-zoom">
                            {item?.isItem ? (
                              <Link
                                to={
                                  "/product/" +
                                  item?.id +
                                  "/" +
                                  convertToSlug(item?.item_name) +
                                  "/" +
                                  isItem
                                }
                                state={{ isItem: item?.isItem }}
                              >
                                <img
                                  className="default-img"
                                  style={{
                                    minHeight: "250px",
                                    maxHeight: "250px",
                                  }}
                                  src={
                                    !_.isEmpty(imagePath)
                                      ? imagePath
                                      : noPhotoFound
                                  }
                                  alt=""
                                />
                              </Link>
                            ) : (
                              <Link
                                to={
                                  "/product/" +
                                  item?.id +
                                  "/" +
                                  convertToSlug(item?.title) +
                                  "/" +
                                  isItem
                                }
                                state={{ isItem: item?.isItem }}
                              >
                                <img
                                  className="default-img"
                                  style={{
                                    minHeight: "250px",
                                    maxHeight: "250px",
                                  }}
                                  src={
                                    item?.groups_image
                                      ? imagePath
                                      : noPhotoFound
                                  }
                                  alt=""
                                />
                              </Link>
                            )}
                          </div>
                          <div className="product-action-1">
                            <a
                              aria-label="Add To Favourites"
                              className="action-btn"
                              href=""
                              onClick={(e) => {
                                // toggleWishlist(item, e);
                                wishlistToggle(item?.id, e, !item?.isItem);
                              }}
                            >
                              {item?.wishlist ? (
                                <i className="wisilist-fill">&#10084;</i>
                              ) : (
                                <i className="fi-rs-heart" />
                              )}
                            </a>
                          </div>
                        </div>
                        <div
                          className="product-content-wrap"
                          style={{ height: "80px" }}

                          // id="hidden"
                        >
                          <div className="product-category">
                            {item?.isItem && (
                              <Link
                                to={
                                  "/product-category/" +
                                  item?.category?.slug +
                                  "/1"
                                  //+ "/" +
                                  // item?.isItem
                                }
                              >
                                {decodeHTMLEntities(
                                  item?.category?.category_name
                                )}
                              </Link>
                            )}
                          </div>
                          <h2>
                            {!item?.isItem ? (
                              <Link
                                to={
                                  "/product/" +
                                  item?.id +
                                  "/" +
                                  convertToSlug(item?.title) +
                                  "/" +
                                  isItem
                                }
                                className="ellipsis"
                                style={{
                                  padding: "0px",
                                  // minHeight: "55px",
                                  // minWidth: "275px",
                                }}
                                state={{ isItem: item?.isItem }}
                              >
                                {/* <textarea className="bg-transparent border-none" style={{border:'none',color:'white'}}  rows={1} > */}

                                {decodeHTMLEntities(item?.title)}
                                {/* </textarea> */}
                              </Link>
                            ) : (
                              <Link
                                to={
                                  "/product/" +
                                  item?.id +
                                  "/" +
                                  convertToSlug(item?.item_name) +
                                  "/" +
                                  isItem
                                }
                                className="ellipsis"
                                style={{
                                  padding: "0px",
                                  // minHeight: "60px",
                                  // minWidth: "275px",
                                }}
                                state={{ isItem: item?.isItem }}
                              >
                                {decodeHTMLEntities(item?.item_name)}
                              </Link>
                            )}
                          </h2>
                          {items[index]?.stock <= 0 && item?.isItem && (
                            <div
                              style={{
                                textAlign: "end",
                                marginRight: "30px",
                              }}
                            >
                              <Blink
                                color="red"
                                text="Out Of Stock!"
                                fontSize="50"
                              >
                                Out of stock!
                              </Blink>
                            </div>
                          )}
                          {/* <div className="product-rate-cover">
                          <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style={{ width: "90%" }}
                            />
                          </div>
                          <span className="font-small ml-5 text-muted">
                            {" "}
                            (4.0)
                          </span>
                        </div>
                        <div>
                          <span className="font-small text-muted">
                            By <a>{item.brand?.brand_name}</a>
                          </span>
                        </div>
                        <div>
                          <span className="font-small text-muted">
                            Product Code : <a>{item?.product_code}</a>
                          </span>
                        </div>
                        <div>
                          <span className="font-small text-muted">
                            Barcode : <a>{item?.custom_barcode}</a>
                          </span>
                        </div> */}
                        </div>
                        <div
                          className="product-content-wrap"
                          // style={{ paddingTop: !item?.isItem && "218px" }}
                          style={{ paddingTop: item?.isItem ? "0px" : "40px" }}
                        >
                          <div className="product-card-bottom">
                            {item?.isItem && (
                              <div className="row">
                                <span className="product-price">
                                  {/* {
                                                        (item?.discount && item?.discount > 0) ?
                                                            <>
                                                                <span>${((item?.discount_type == 'Percentage') ? item?.sales_price - (item?.sales_price * item?.discount / 100) : (item?.discount_type == 'Fixed') ? item?.sales_price - item?.discount : item?.sales_price).toFixed(2)} </span>
                                                                <span className="old-price">${item?.sales_price.toFixed(2)}</span>
                                                            </>
                                                            : */}
                                  <span>${item?.sales_price.toFixed(2)} </span>
                                </span>
                                <span className="user-id-font">
                                  <span> {userId}</span>
                                </span>
                              </div>
                            )}
                            {item?.isItem && (
                              <div className="detail-qty group-item?-qty border radius">
                                <a
                                  onClick={(e) => {
                                    if (items[index]?.stock > 0) {
                                      handleCartQuantity(
                                        index,
                                        Number(item?.cart?.quantity - 1)
                                      );
                                    }
                                  }}
                                  className="qty-down"
                                >
                                  <i className="fi-rs-angle-small-down" />
                                </a>
                                <input
                                  type="text"
                                  name="cart_qty"
                                  className="qty-val"
                                  min={0}
                                  disabled={items[index]?.stock <= 0}
                                  onChange={(e) => {
                                    if (items[index]?.stock > 0) {
                                      handleCartQuantity(index, e.target.value);
                                    }
                                  }}
                                  value={item?.cart?.quantity}
                                />
                                <a
                                  onClick={(e) => {
                                    if (items[index]?.stock > 0) {
                                      handleCartQuantity(
                                        index,
                                        Number(item?.cart?.quantity + 1)
                                      );
                                    }
                                  }}
                                  className="qty-up"
                                >
                                  <i className="fi-rs-angle-small-up" />
                                </a>
                              </div>
                            )}
                          </div>
                          {item?.isItem ? (
                            <p
                              onClick={(e) => {
                                e?.preventDefault();
                                if (items[index]?.stock > 0) {
                                  handleAddToCart(item, e);
                                }
                              }}
                              className="btn w-100 hover-up mt-10"
                              style={{
                                padding: "8px",

                                backgroundColor:
                                  items[index]?.stock <= 0 && "gray",
                              }}
                            >
                              {item?.isItem && (
                                <i className="fi-rs-shopping-cart mr-5" />
                              )}
                              {item?.isItem ? "Add to Cart" : "View More"}
                            </p>
                          ) : (
                            <Link
                              to={
                                "/product/" +
                                item?.id +
                                "/" +
                                convertToSlug(item?.title) +
                                "/" +
                                isItem
                              }
                              state={{ isItem: item?.isItem }}
                            >
                              <p
                                className="btn w-100 hover-up mt-10"
                                style={{ padding: "8px" }}
                              >
                                View More
                              </p>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {items.length == 0 ? (
                  <h5 className="text-center">No Item Available</h5>
                ) : null}
              </div>
            ) : (
              <Loader />
            )}
            {/*product grid*/}
            {items.length > 0 ? (
              <div className="pagination-area mt-20 mb-20">
                <nav
                  aria-label="Page navigation example"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ul className="pagination justify-content-start">
                    {links?.map((link, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            "page-item" +
                            (link.active ? " page-link-active" : "")
                          }
                        >
                          <a
                            className={"page-link page-arrow page-link-active"}
                            style={{
                              backgroundColor: link.active ? "#E57D26" : "",
                            }}
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              if (link.url) {
                                setPage(link.url?.split("page=")[1]);
                              }
                            }}
                          >
                            {link.label
                              .replace(/&laquo; Previous/g, "<<")
                              .replace(/Next &raquo;/g, ">>")}
                          </a>
                        </li>
                      );
                    })}

                    {/* 
                      <li className="page-item active">
                        <a className="page-link page-number" href="#">
                          {page}
                        </a>
                      </li>

                      <li className="page-item">
                        <a className={"page-link page-arrow" + (perPage != items.length ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page + 1) }}>
                          <i className="fi-rs-arrow-small-right" />
                        </a>
                      </li> */}
                  </ul>
                </nav>
              </div>
            ) : null}
          </div>
          <div
            className="col-lg-1-5 primary-sidebar sticky-sidebar"
            style={{
              position: "relative",
              overflow: "visible",
              boxSizing: "border-box",
              minHeight: 1,
            }}
          >
            {/* Fillter By Price */}
            {/* Product sidebar Widget */}
            <div
              className="theiaStickySidebar"
              style={{
                paddingTop: 0,
                paddingBottom: 1,
                position: "static",
                transform: "none",
                top: 0,
                left: "159.5px",
              }}
            >
              <div className="sidebar-widget widget-category-2 mb-30">
                <h5 className="section-title style-1 mb-30">Sub Category</h5>
                <div className="main-cat-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => handleMainCategoryChange(e)}
                    checked={selectedCategories.includes(
                      category?.main_category?.slug
                    )}
                    className="form-check-input mr-20"
                    value={category?.main_category?.slug}
                    id="cat"
                    name=""
                  />
                  <img
                    width={30}
                    src="/images/theme/icons/category-1.svg"
                    alt=""
                  />
                  <span>
                    {" "}
                    {decodeHTMLEntities(
                      category?.main_category?.category_name
                    )}{" "}
                  </span>
                </div>
                <ul>
                  {category?.subcategory?.map((c, i) => {
                    return (
                      <li key={i} style={{ justifyContent: "inherit" }}>
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(e) => handleCategoryChange(e)}
                          checked={selectedCategories.includes(c.slug)}
                          className="form-check-input "
                          style={{ padding: "5px" }}
                          value={c?.slug}
                          id={"cat" + i}
                          name=""
                        />
                        <a
                          style={{ marginLeft: "12px" }}
                          onClick={(e) => e.preventDefault()}
                        >
                          {" "}
                          <img
                            src="/images/theme/icons/category-1.svg"
                            alt=""
                          />
                          {decodeHTMLEntities(c?.category_name)}
                        </a>
                        {/* <span className="count">30</span> */}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="sidebar-widget  range mb-30">
                <h5 className="section-title style-1 mb-30">Fill by price</h5>
                <div className="price-filter">
                  {/* <div className="price-filter-inner">
                <div
                  id="slider-range"
                  className="mb-20 noUi-target noUi-ltr noUi-horizontal noUi-background"
                >
                  <div className="noUi-base">
                    <div
                      className="noUi-origin noUi-connect"
                      style={{ left: "25%" }}
                    >
                      <div className="noUi-handle noUi-handle-lower" onMouseMove={(e) => console.log("MOUSE MOVE", e)} onDragOver={(e) => console.log("EVENT",e)} />
                    </div>
                    <div
                      className="noUi-origin noUi-background"
                      style={{ left: "50%" }}
                    >
                      <div className="noUi-handle noUi-handle-upper" />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="caption">
                    From:{" "}
                    <strong id="slider-range-value1" className="text-brand">
                      $500
                    </strong>
                  </div>
                  <div className="caption">
                    To:{" "}
                    <strong id="slider-range-value2" className="text-brand">
                      $1,000
                    </strong>
                  </div>
                </div>
              </div> */}

                  <div className="price-filter-inner">
                    <div className="row pb-1">
                      <div className="col">
                        <input
                          type="number"
                          placeholder="Min Price"
                          value={minPrice}
                          onChange={(e) => setMinPrice(e.target.value)}
                          className="form-control-xs "
                          style={{ padding: "8px" }}
                          name=""
                          id=""
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          placeholder="Max Price"
                          value={maxPrice}
                          onChange={(e) => setMaxPrice(e.target.value)}
                          className="form-control-xs "
                          style={{ padding: "8px" }}
                          name=""
                          id=""
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="caption">
                        From:{" "}
                        <strong id="slider-range-value1" className="text-brand">
                          ${minPrice}
                        </strong>
                      </div>
                      <div className="caption">
                        To:{" "}
                        <strong id="slider-range-value2" className="text-brand">
                          ${maxPrice}
                        </strong>
                      </div>
                    </div>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        handlePriceFilter();
                      }}
                      className="btn btn-sm btn-default"
                    >
                      <i className="fi-rs-filter mr-5" /> Fillter
                    </a>
                  </div>
                </div>
              </div>

              {/* Filter by Brand */}
              <div className="sidebar-widget range mb-30">
                <h5 className="section-title style-1 mb-0">Brand</h5>

                <div className="list-group">
                  <div
                    ref={brandRef}
                    className={
                      "list-group-item mb-10 mt-10" +
                      (showAllBrands ? " see-all-brands" : "")
                    }
                  >
                    <div className="custome-checkbox">
                      {brands.map((brand, index) => {
                        if (showAllBrands) {
                          return (
                            <>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id={"brand-" + index}
                                value={brand.brand_name}
                                // checked={selectedBrands?.includes(brand?.id)}
                                checked={selectedBrands
                                  ?.map(String)
                                  ?.includes(String(brand?.id))}
                                onChange={() => handleBrandChange(brand.id)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"brand-" + index}
                              >
                                <span>{brand.brand_name}</span>
                              </label>
                              <br />
                            </>
                          );
                        } else {
                          if (index < 10) {
                            return (
                              <>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkbox"
                                  id={"brand-" + index}
                                  value={brand.brand_name}
                                  onChange={() => handleBrandChange(brand.id)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"brand-" + index}
                                >
                                  <span>{brand.brand_name}</span>
                                </label>
                                <br />
                              </>
                            );
                          }
                        }
                      })}
                    </div>
                  </div>
                  {brands.length > 10 ? (
                    <a
                      // href=""
                      onClick={(e) => {
                        e.preventDefault();
                        // setShowAllBrands(!showAllBrands);
                      }}
                    >
                      {/* {showAllBrands ? "See less..." : "See all..."} */}
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductCategory;
