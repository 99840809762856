import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { convertToSlug } from "../../utils/constant";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import MessageBox from "../MessageBox";

const ConfirmationPopupModal = ({
  show,
  setShow,
  isRegister,
  modalTitle,
  content,
  setaddressId,
  isMessage=false,
  confirmClicked,
  isAddress,
  isOk,
  addressNum,
  onClose,
  addressId,
  setaddressNum,
  addressData,
  title,
}) => {
  let data = addressData?.addresses;
  // data.push({
  //   address: addressData?.address,
  //   pincode: addressData?.pincode,
  //   state: addressData?.state,
  //   city: addressData?.city,
  //   isDefault: true,
  // });

  return (
    <Modal
      show={show}
      onHide={() => {
        if (!isAddress) {
          setShow(false);
        } else {
          onClose();
        }
      }}
      size={isAddress || isRegister ? "lg" : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {!isMessage ? (
        <>
          {!isAddress ? (
            <>
              {title && (
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                  </Modal.Title>
                </Modal.Header>
              )}
              {!isRegister ? (
                <Modal.Body>
                  <h4>{modalTitle}</h4>
                  <p>{content}</p>
                </Modal.Body>
              ) : (
                <Modal.Body>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h6 style={{ marginTop: "5px" }}>
                      Thank you for registering!
                    </h6>
                    <h6 style={{ marginTop: "30px" }}>You're almost done!</h6>
                    <p style={{ marginTop: "15px" }}>
                      Silverline Wholesale, Inc. has received your information.
                      Please fax a copy of your business license to us at
                      (630)-785-6845 or email it to
                      info@silverlinewholesale.com. If you have not already
                      uploaded your information during the registration process.
                      Once we have received and reviewed the required
                      documentation we will activate your account. If you have
                      registered during our normal business hours and have faxed
                      or emailed your certificate and would like immediate
                      access to your account then call us @ (630)-785-6838 so a
                      sales representative can verify and activate your account.
                    </p>
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <span>Thank you!</span>
                    <br />
                    <span>Silverline Wholesale, Inc.</span>
                    <br />
                    <span>Ph: (630) 785-6838</span>
                    <br />
                    <span>
                      Note: This email address was given to us by one of our
                      customers. If you did not signup to be a member, please
                      send an email to 
                      <a href="mailto:info@silverlinewholesale.com">
                        {" "}
                        info@silverlinewholesale.com.
                      </a>
                    </span>
                  </div>
                </Modal.Body>
              )}
            </>
          ) : (
            <>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {title}
                </Modal.Title>
              </Modal.Header>
              <div
                className="table-responsive order_table checkout  "
                style={{ height: "180px" }}
              >
                <table className="table no-border ">
                  <tbody>
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setaddressId(addressData?.state_id);
                      }}
                    >
                      <td
                        // className="image product-thumbnail"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <input
                          class="form-check-input mb-5"
                          type="radio"
                          // checked={i?.isDefault}
                          checked={
                            Number(addressId) === Number(addressData?.state_id)
                          }
                          id="flexCheckChecked"
                          onChange={() => {
                            setaddressNum(0);
                          }}
                          style={{ width: "20px", height: "20px" }}
                          // checked
                        />
                      </td>
                      <td>
                        <h6 className=" mb-5">
                          <p
                            // target="_blank"
                            className="text-heading"
                            // to={
                            //   "/product/" +
                            //   addressData.item_id +
                            //   "/" +
                            //   convertToSlug(addressData.item_name) +
                            //   "/" +
                            //   true
                            // }
                          >
                            {addressData?.address} {addressData?.pincode} ,{" "}
                            {addressData?.city} ,{addressData?.state?.state}
                          </p>
                        </h6>
                      </td>
                      {/* <td>
                      <h6 className="text-muted pl-20 pr-20">x {i.quantity}</h6>
                    </td>
                    <td>
                      <h4 className="text-brand">${i.subtotal.toFixed(2)}</h4>
                    </td> */}
                    </tr>
                    {data?.map((i, index) => {
                      return (
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setaddressId(i?.state_id);
                          }}
                        >
                          <td
                            // className="image product-thumbnail"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              class="form-check-input mb-5"
                              type="radio"
                              checked={
                                Number(addressId) === Number(i?.state_id)
                              }
                              onChange={(e) => {
                                setaddressNum(index + 1);
                              }}
                              id="flexCheckChecked"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </td>
                          <td>
                            <h6 className=" mb-5">
                              <p
                                // target=""
                                className="text-heading"
                              >
                                {i?.address} {i?.pincode} , {i?.city} ,
                                {i?.state?.state}
                              </p>
                            </h6>
                          </td>
                          {/* <td>
                      <h6 className="text-muted pl-20 pr-20">x {i.quantity}</h6>
                    </td>
                    <td>
                      <h4 className="text-brand">${i.subtotal.toFixed(2)}</h4>
                    </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {isAddress && (
                <Link
                  to={
                    "/my-account/add-address/billing?add=" +
                    JSON.stringify({
                      address: "",
                      city: "",
                      state_id: "",
                      state_name: "",
                      pincode: "",
                      isAddAddress: true,
                    })
                  }
                  className="pb-3 p-2"
                  style={{ textAlign: "end" }}
                >
                  <h6 className="text-brand">Add Address ?</h6>
                </Link>
              )}
            </>
          )}
        </>
      ) : (
        <MessageBox />
      )}
      {!isOk ? (
        <Modal.Footer>
          <Button className={" "} onClick={confirmClicked}>
            {isAddress ? "Update" : "Yes"}
          </Button>

          <Button onClick={() => setShow(false)}>
            {isAddress ? "Close" : "No"}
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button className={" "} onClick={confirmClicked}>
            Ok
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ConfirmationPopupModal;
