import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  getCartItems,
  increaseDecreaseCartItemQty,
  getAddressDetailsAPI,
  removeItemFromCart,
  increaseDescreaseInputField,
} from "../../services/CartService";
import { AppContext } from "../../utils/AppProvider";
import { convertToSlug, decodeHTMLEntities } from "../../utils/constant";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../Loader";
import Blink from "react-blink-text";
import { userId } from "../../layout/Index";

const Cart = () => {
  const [items, setItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [totalTaxAmount, settotalTaxAmount] = useState(0);
  const [addressDetails, setaddressDetails] = useState([]);
  const [total, setTotal] = useState(0);
  const [rate, setRate] = useState(0);
  const [isNotAvailable, setisNotAvailable] = useState(false);
  const [popUpmodal, setpopUpmodal] = useState(false);
  const [proceedWithCartPopup, setproceedWithCartPopup] = useState(false);
  const { cart_qty, setCartQty } = useContext(AppContext);
  const [deletePopup, setdeletePopup] = useState(false);
  const [isAddressPopup, setisAddressPopup] = useState(true);
  const [deleteItem, setdeleteItem] = useState({});
  const [addressNum, setaddressNum] = useState(0);
  const [addressId, setaddressId] = useState(0);
  const [addressIdFinal, setaddressIdFinal] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [cartQuantityChange, setcartQuantityChange] = useState(false);
  const [isOutOfStockItemAvalaible, setisOutOfStockItemAvalaible] =
    useState(false);
  const [qtyErrorMessages, setqtyErrorMessages] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    // getCartDetails();
    getAddressDetails();
  }, []);

  // let localStorageCartStateId = localStorage.getItem("cartStateId");

  const getCartDetails = (id, updatation) => {
    let stateFinalId = id || addressIdFinal;
    getCartItems(stateFinalId)
      .then((response) => {
          setisLoading(false);

console.log(response.data.data,"<<<");
        let st = 0;
        let totalTax = 0;
        if (response.data) {
          response.data.data.map((i, index) => {
            st += i.quantity * i.item.sales_price;
            if (i?.item?.taxValue?.tax_product_amount) {
              totalTax += i?.quantity * i?.item?.taxValue?.tax_product_amount;
            }
          });
          setqtyErrorMessages(response.data?.messages);
          setSubtotal(st.toFixed(2));
          settotalTaxAmount(totalTax?.toFixed(2));
          setTotal(Number(st.toFixed(2)) + Number(totalTax?.toFixed(2)));
          if (updatation) {
            getAddressDetails(true);
          }
           for (let a of response.data.data) {
             if (!a?.item?.showOnWeb||!a?.item?.status) {
               setisNotAvailable(true);
             }
           }
           
          setItems(response.data.data);

        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  
  const getAddressDetails = (updatation) => {
    getAddressDetailsAPI()
      .then((response) => {
        // let st = 0;
        if (response.data) {
          setaddressDetails(response?.data?.data[0]);
          if (!updatation) {
            // if (isNaN(Number(localStorageCartStateId))) {
            // getCartDetails(localStorageCartStateId);
            // setaddressId(localStorageCartStateId);
            // setaddressIdFinal(localStorageCartStateId);
            // } else {
            getCartDetails(response?.data?.data[0]?.state_id);
            setaddressId(response?.data?.data[0]?.state_id);
            setaddressIdFinal(response?.data?.data[0]?.state_id);
            // }
          }
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    let st = 0;
    let totalTax = 0;
    items.length > 0 &&
      items.map((i) => {
        st += i.quantity * i.item.sales_price;
        if (i?.item?.taxValue?.tax_product_amount) {
          totalTax += i?.quantity * i?.item?.taxValue?.tax_product_amount;
        }
      });

    if (st === 0) setTotal(0);
    else setTotal((st + rate + totalTax).toFixed(2));
  }, [rate, subtotal]);

  useEffect(() => {
    let st = 0;
    let totalTax = 0;
    let outOfStockItemAvalaible = false;

    items.map((i) => {
      st += i.quantity * i.item.sales_price;
      if (i?.item?.taxValue?.tax_product_amount) {
        totalTax += i?.quantity * i?.item?.taxValue?.tax_product_amount;
      }
      if (i?.item?.stock > 0 && i?.item?.stock >= i?.quantity) {
      } else {
        outOfStockItemAvalaible = true;
      }
    });

    setisOutOfStockItemAvalaible(outOfStockItemAvalaible);

    setSubtotal(st.toFixed(2));
    settotalTaxAmount(totalTax?.toFixed(2));

    if (st == 0) setTotal(0);
    else setTotal((st + rate + totalTax).toFixed(2));
  }, [items]);

  const handleRemoveItem = (e, item) => {
    e.preventDefault();

    removeItemFromCart(item.id)
      .then((response) => {
        let _items = items.filter((i) => i.id != item.id);
        setItems(_items);
        setCartQty(cart_qty - item.quantity);
        setdeleteItem({});
        setdeletePopup(false);
        toast("Item Removed from Cart", { type: "success" });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleRemoveAll = (e) => {
    e.preventDefault();
    removeItemFromCart("all")
      .then((response) => {
        setItems([]);
        setpopUpmodal(false);
        setRate(0);
        setCartQty(0);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const decreaseQuantity = (item_id, index) => {
    setcartQuantityChange(true);

    increaseDecreaseCartItemQty(item_id, false)
      .then((response) => {
        let _items = items.map((it, i) => {
          if (i == index) {
            return { ...it, quantity: it.quantity - 1 };
          }
          return it;
        });

        setItems(_items);
        setCartQty(cart_qty - 1);
        setcartQuantityChange(false);
      })
      .catch((error) => {
        setcartQuantityChange(false);
        if (error?.response?.status == 422)
          toast(error.response.data.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response?.data?.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
        console.log("Error:", error);
      });
  };

  const modifiedItemQty = (item_id, index, number, stock) => {
    if (number <= stock) {
      setcartQuantityChange(true);
      increaseDescreaseInputField(item_id, parseInt(number))
        .then((response) => {
          let _items = items.map((it, i) => {
            if (i == index) {
              return { ...it, quantity: parseInt(number) };
            }
            return it;
          });

          setItems(_items);
          setCartQty(parseInt(number));
          setcartQuantityChange(false);
        })
        .catch((error) => {
          setcartQuantityChange(false);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error?.response?.data?.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
          console.log("Error:", error);
        });
    } else {
      toast(`There are only ${stock} item available`, { type: "error" });
    }
  };

  const updateAddressValue = () => {
    if (Number(addressId) !== Number(addressIdFinal)) {
      setItems([]);
      setaddressDetails([]);

      getCartDetails(
        addressDetails?.addresses[addressNum - 1]?.state?.id,
        true
      );
      setaddressIdFinal(addressDetails?.addresses[addressNum - 1]?.state?.id);
      setaddressId(addressDetails?.addresses[addressNum - 1]?.state?.id);

      // localStorage.setItem(
      //   "cartStateId",
      //   Number(addressDetails?.addresses[addressNum - 1]?.state?.id)
      // );
    }
    setisAddressPopup(false);
  };

  const onCloseAddressPopup = () => {
    if (Number(addressId) === Number(addressIdFinal)) {
      setisAddressPopup(false);
    } else {
      setaddressId(addressIdFinal);
      setisAddressPopup(false);
    }
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <div className="">
        {/* cart-dropdown-hm2 account-dropdown h-50 */}
        <ul>
          <li className="mr-3">
            <span
              className="text-brand"
              style={{ minWidth: "65px", maxWidth: "80px" }}
            >
              Tax Name :
            </span>
            <span> {}</span>
          </li>
          <li className="mr-3">
            <span
              className="text-brand"
              style={{ minWidth: "65px", maxWidth: "80px" }}
            >
              Tax type :
            </span>
            <span> {}</span>
          </li>
          <li className="mr-3">
            <span
              className="text-brand"
              style={{ minWidth: "65px", maxWidth: "80px" }}
            >
              Tax Amount :
            </span>
            <span> {}</span>
          </li>
          <li className="mr-3">
            <span
              className="text-brand"
              style={{ minWidth: "65px", maxWidth: "80px" }}
            >
              Tax Unit :
            </span>
            <span> {}</span>
          </li>
        </ul>
      </div>
    </Tooltip>
  );

  const increaseQuantity = (
    item_id,
    index,
    item_qty,
    item_stock,
    purchase_limit
  ) => {
    if (
      item_qty < item_stock ||
      (purchase_limit != null && item_qty < purchase_limit)
    ) {
      setcartQuantityChange(true);

      increaseDecreaseCartItemQty(item_id, true)
        .then((response) => {
          if (response.data.message == "no_stock") {
            toast("Item Stock Not Availbale.", { type: "error" });
            setcartQuantityChange(false);
          } else {
            let _items = items.map((it, i) => {
              if (i == index) {
                return { ...it, quantity: it.quantity + 1 };
              }
              return it;
            });

            setItems(_items);
            setCartQty(parseInt(cart_qty) + 1);
            setcartQuantityChange(false);
          }
        })
        .catch((error) => {
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error?.response?.data?.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
          setcartQuantityChange(false);
        });
    } else {
      toast("Out of stock.", { type: "error" });
    }
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Cart
          </div>
        </div>
      </div>
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-9 mb-40">
            <h1 className="heading-2 mb-10">Your Cart</h1>
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand"> {cart_qty} </span>{" "}
                products in your cart
              </h6>
              <h6 className="text-body d-none">
                <a href="#" className="text-muted">
                  <i className="fi-rs-trash mr-5 " />
                  Clear Cart
                </a>
              </h6>
            </div>
            {total < 199.99 && (
              <div
                className="alert alert-danger mt-3 "
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "6px",
                  marginRight: "5px",
                }}
                role="alert"
              >
                <i className="fi-rs-cross-circle ml-3" />
                Minimum Order value $200 to proceed with your order!
              </div>
            )}

            {isNotAvailable && (
              <div
                className="alert alert-danger mt-3 "
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "6px",
                  marginRight: "5px",
                }}
                role="alert"
              >
                <i className="fi-rs-cross-circle ml-3" />
                Blur items are not currently accessible on the website, remove
                them to proceed to checkout.{" "}
              </div>
            )}

            {total > 199.99 && isOutOfStockItemAvalaible && (
              <div
                className="alert alert-danger mt-3 "
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "6px",
                  marginRight: "5px",
                }}
                role="alert"
              >
                <i className="fi-rs-cross-circle ml-3" />
                Please delete or change the quantity of out of stock items{" "}
              </div>
            )}
          </div>
        </div>
        {!isLoading ? (
          <div className="row">
            <div className="col-lg-9">
              <div
                className="table-responsive shopping-summery"
                style={{
                  height: "670px",
                  backgroundColor: "var(--dark-container)",
                }}
              >
                <table className="table table-wishlist">
                  <thead
                    style={{
                      top: "0",
                      position: "sticky",
                      zIndex: "1",
                    }}
                  >
                    <tr className="main-heading">
                      {/* <th className="custome-checkbox start pl-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    id="exampleCheckbox11"
                    defaultValue=""
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheckbox11"
                  />
                </th> */}
                      <th scope="col" className="text-center" colSpan={2}>
                        Product
                      </th>
                      <th scope="col" className="text-center">
                        {/* Stock Status */}
                      </th>
                      <th scope="col">Unit Price</th>
                      <th scope="col" className="text-center">
                        Quantity
                      </th>
                      <th scope="col">Subtotal</th>
                      <th scope="col" className="text-center">
                        Tax
                      </th>
                      <th scope="col" className="text-center">
                        Grand Total
                      </th>

                      <th scope="col" className="end">
                        Remove
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {items.length > 0 ? (
                      items.map((item, index) => {
                        let titleName = decodeHTMLEntities(
                          item?.item?.item_name
                        );
                        let totalAmount = parseFloat(
                          Number(item.item.sales_price * item.quantity).toFixed(
                            2
                          )
                        );
                        if (items[index]?.item?.taxValue?.tax_product_amount) {
                          totalAmount =
                            totalAmount +
                            parseFloat(
                              Number(
                                items[index]?.item?.taxValue
                                  ?.tax_product_amount * item.quantity
                              ).toFixed(2)
                            );
                        }
                        let ell = document?.getElementById(
                          `disableIdd${index}`
                        );
                        ell?.addEventListener("contextmenu", (ev) => {
                          ev?.preventDefault(); // this will prevent browser default behavior
                        });
                        let isDisable;
                        let outOfStockMessage;

                        if (
                          item?.item?.stock > 0 &&
                          item?.item?.stock >= item?.quantity
                        ) {
                          isDisable = false;
                        } else {
                          isDisable = true;
                          if (item?.item?.stock > 0) {
                            outOfStockMessage = `Out of Stock! There are only ${item?.item?.stock} quantity available in this item`;
                          } else {
                            outOfStockMessage = "Out of Stock!";
                          }
                        }
                        let qtyLimtMsg = {
                          isLimitExceeded: false,
                          message: "",
                        };
                        if (qtyErrorMessages) {
                          for (let a of qtyErrorMessages) {
                            if (a?.item_id === item?.item_id) {
                              qtyLimtMsg = {
                                isLimitExceeded: true,
                                message: "Maxium Quantity Reached",
                              };
                            }
                          }
                        }
                        let isBlur =item?.item?.showOnWeb && item?.item?.status;
                        return (
                          <>
                            <tr
                              key={index}
                              className="d-lg-none d-md-none mobile-w-c pt-30 "
                            >
                              <div
                                className="row"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <div
                                  className="col-4"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td className="image product-thumbnail pt-40">
                                    <Link
                                      style={{ marginLeft: "5px" }}
                                      to={
                                        "/product/" +
                                        item?.item_id +
                                        "/" +
                                        convertToSlug(item?.item?.item_name) +
                                        "/" +
                                        true
                                      }
                                      title={item.item.item_name}
                                      state={{ isItem: true }}
                                    >
                                      <img
                                        width={90}
                                        height={90}
                                        src={
                                          item?.image
                                            ? process.env.REACT_APP_BASE_URL +
                                              "/storage/products/" +
                                              item.image
                                            : process.env.REACT_APP_BASE_URL +
                                              "/storage/products/no-image.png"
                                        }
                                        alt="#"
                                      />
                                    </Link>
                                  </td>
                                </div>
                                <div
                                  className="col-8 align-items-center justify-content-center d-flex"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td
                                    className="product-des product-name"
                                    style={
                                      {
                                        // backgroundColor: isDisable && "#A4A9A9",
                                      }
                                    }
                                  >
                                    <h6 className="mb-5">
                                      <Link
                                        className="product-name mb-10 text-heading"
                                        to={
                                          "/product/" +
                                          item?.item_id +
                                          "/" +
                                          convertToSlug(item.item.item_name) +
                                          "/" +
                                          true
                                        }
                                        title={titleName}
                                        state={{ isItem: true }}
                                      >
                                        {titleName}
                                      </Link>
                                    </h6>
                                    <div className="product-rate-cover">
                                      <div className="product-rate d-inline-block">
                                        <div
                                          className="product-rating"
                                          style={{ width: "90%" }}
                                        ></div>
                                      </div>
                                      <span className="font-small ml-5 text-muted">
                                        {" "}
                                        (4.0)
                                      </span>
                                      <ul className="">
                                        {item.item?.brand?.brand_name && (
                                          <li className=" ">
                                            Brand :{" "}
                                            <span className="text-brand mr-3">
                                              {item.item?.brand?.brand_name}
                                            </span>
                                          </li>
                                        )}
                                        {item?.item?.product_code && (
                                          <li>
                                            Product Code :{" "}
                                            <span className="text-brand mr-3">
                                              {" "}
                                              {item?.item?.product_code}
                                            </span>
                                          </li>
                                        )}
                                        {item.item?.category?.category_name && (
                                          <li className=" ">
                                            Category :{" "}
                                            <span className="text-brand mr-3">
                                              {
                                                item.item?.category
                                                  ?.category_name
                                              }
                                            </span>
                                          </li>
                                        )}

                                        {item?.item?.color?.title && (
                                          <li className=" ">
                                            Color :{" "}
                                            <span className="text-brand mr-3">
                                              {" "}
                                              {item?.item?.color?.title}
                                            </span>
                                          </li>
                                        )}
                                        {item?.item?.size?.title && (
                                          <li>
                                            Size :{" "}
                                            <span className="text-brand mr-3">
                                              {" "}
                                              {item?.item?.size?.title}
                                            </span>
                                          </li>
                                        )}
                                        {/* <li className="">
                                    Stock :
                                    <span className="in-stock text-brand ml-5">
                                      {" "}
                                      {item?.item?.stock} Items In Stock
                                    </span>
                                  </li> */}
                                        {item?.item?.flavor?.title && (
                                          <li>
                                            Flavor :{" "}
                                            <span className="text-brand">
                                              {" "}
                                              {item?.item?.flavor?.title}
                                            </span>
                                          </li>
                                        )}
                                        {item.item?.unit?.unit_name && (
                                          <li className=" ">
                                            Unit :{" "}
                                            <span className="text-brand mr-3">
                                              {item.item?.unit?.unit_name}
                                            </span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                    {isDisable && (
                                      <div
                                        className="alert alert-danger mt-3 "
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                          alignItems: "center",
                                          gap: "6px",
                                          marginRight: "5px",
                                        }}
                                        role="alert"
                                      >
                                        <i className="fi-rs-cross-circle ml-3" />
                                        <Blink
                                          color="red"
                                          text={outOfStockMessage}
                                          fontSize="50"
                                        >
                                          {outOfStockMessage}
                                        </Blink>
                                      </div>
                                    )}
                                    {qtyLimtMsg?.isLimitExceeded && (
                                      <div
                                        className="alert alert-danger mt-3 "
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                          alignItems: "center",
                                          gap: "6px",
                                          marginRight: "5px",
                                        }}
                                        role="alert"
                                      >
                                        <i className="fi-rs-cross-circle ml-3" />
                                        <Blink
                                          color="red"
                                          text={qtyLimtMsg?.message}
                                          fontSize="50"
                                        >
                                          {qtyLimtMsg?.message}
                                        </Blink>
                                      </div>
                                    )}
                                  </td>
                                </div>
                                <div
                                  className="col-6"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td
                                    className="price"
                                    style={
                                      {
                                        // backgroundColor: isDisable && "#A4A9A9",
                                      }
                                    }
                                  >
                                    <h6 className="text-body">
                                      ${item?.item?.sales_price?.toFixed(2)}
                                    </h6>
                                    <span className="user-id-font">
                                      <span> {userId}</span>
                                    </span>
                                  </td>
                                </div>
                                <div
                                  className="col-6"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td
                                    className="price"
                                    style={
                                      {
                                        // backgroundColor: isDisable && "#A4A9A9",
                                      }
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "2px",
                                      }}
                                    >
                                      <span>Tax:-</span>
                                      {items[index]?.item?.taxValue
                                        ?.tax_product_amount ? (
                                        <p className="text-brand ">
                                          $
                                          {(
                                            items[index]?.item?.taxValue
                                              ?.tax_product_amount *
                                            item.quantity
                                          ).toFixed(2)}
                                        </p>
                                      ) : (
                                        <p className="text-brand">NA</p>
                                      )}
                                      {items[index]?.item?.taxValue && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              {items[index]?.item?.taxValue ? (
                                                <div className="product-rate-cover">
                                                  {/* cart-dropdown-hm2 account-dropdown h-50 */}
                                                  <ul>
                                                    {items[index]?.item
                                                      ?.taxValue?.name && (
                                                      <li className="mr-3">
                                                        <span
                                                          className="text-brand"
                                                          style={{
                                                            minWidth: "65px",
                                                            maxWidth: "80px",
                                                          }}
                                                        >
                                                          Tax Name :
                                                        </span>
                                                        <span>
                                                          {
                                                            items[index]?.item
                                                              ?.taxValue?.name
                                                          }
                                                        </span>
                                                      </li>
                                                    )}
                                                    {items[index]?.item
                                                      ?.taxValue
                                                      ?.price_type && (
                                                      <li className="22">
                                                        <span
                                                          className="text-brand"
                                                          style={{
                                                            minWidth: "65px",
                                                            maxWidth: "80px",
                                                          }}
                                                        >
                                                          Tax type :
                                                        </span>
                                                        <span>
                                                          {" "}
                                                          {
                                                            items[index]?.item
                                                              ?.taxValue
                                                              ?.price_type
                                                          }
                                                        </span>
                                                      </li>
                                                    )}
                                                    {items[index]?.item
                                                      ?.taxValue
                                                      ?.tax_product_amount && (
                                                      <li className="22">
                                                        <span
                                                          className="text-brand"
                                                          style={{
                                                            minWidth: "65px",
                                                            maxWidth: "80px",
                                                          }}
                                                        >
                                                          Tax Amount :
                                                        </span>
                                                        <span>
                                                          {" "}
                                                          {items[
                                                            index
                                                          ]?.item?.taxValue?.tax_product_amount.toFixed(
                                                            2
                                                          )}
                                                        </span>
                                                      </li>
                                                    )}
                                                    {items[index]?.item
                                                      ?.taxValue?.tax_unit && (
                                                      <li className="mr-3">
                                                        <span
                                                          className="text-brand"
                                                          style={{
                                                            minWidth: "65px",
                                                            maxWidth: "80px",
                                                          }}
                                                        >
                                                          Tax Unit :
                                                        </span>
                                                        <span>
                                                          {" "}
                                                          {
                                                            items[index]?.item
                                                              ?.taxValue
                                                              ?.tax_unit
                                                          }
                                                        </span>
                                                      </li>
                                                    )}
                                                  </ul>
                                                </div>
                                              ) : (
                                                <span>NA</span>
                                              )}
                                            </Tooltip>
                                          }
                                        >
                                          <i
                                            className="fi-rs-info "
                                            style={{ paddingTop: "5px" }}
                                          />
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  </td>
                                </div>
                                <div
                                  className="col-5"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td
                                    className="text-center detail-info"
                                    data-title="Stock"
                                    style={
                                      {
                                        // backgroundColor: isDisable && "#A4A9A9",
                                      }
                                    }
                                  >
                                    <div
                                      className="detail-extralink"
                                      style={{ marginRight: "-20px" }}
                                    >
                                      <div className="detail-qty border radius">
                                        <a
                                          href=""
                                          disabled={cartQuantityChange}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (item.quantity > 1) {
                                              decreaseQuantity(item.id, index);
                                            } else {
                                              setdeleteItem(item);
                                              setdeletePopup(true);
                                            }
                                          }}
                                          className="qty-down"
                                        >
                                          <i className="fi-rs-angle-small-down" />
                                        </a>
                                        {/* <span className="qty-val">
                                        {item?.quantity}
                                      </span> */}
                                        <input
                                          className="qty-val"
                                          value={item?.quantity}
                                          style={{
                                            color:
                                              cartQuantityChange &&
                                              "var(--hover-color)",
                                          }}
                                          type="number"
                                          min={0}
                                          disabled={cartQuantityChange}
                                          onChange={(e) => {
                                            if (
                                              typeof (
                                                Number(e?.target?.value) ===
                                                "number"
                                              ) ||
                                              e?.target?.value === ""
                                            ) {
                                              let countQty =
                                                e?.target?.value === ""
                                                  ? 0
                                                  : e?.target?.value;
                                              if (countQty > 0) {
                                                modifiedItemQty(
                                                  item?.id,
                                                  index,
                                                  countQty,
                                                  item?.item?.stock
                                                );
                                              } else {
                                                setdeleteItem(item);
                                                setdeletePopup(true);
                                              }
                                            }
                                          }}
                                        />
                                        <a
                                          href=""
                                          disabled={cartQuantityChange}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            increaseQuantity(
                                              item.id,
                                              index,
                                              item.quantity,
                                              item.item?.stock,
                                              item.item?.purchase_limit
                                            );
                                          }}
                                          className="qty-up"
                                        >
                                          <i className="fi-rs-angle-small-up" />
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </div>
                                <div
                                  className="col-7"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td
                                    className="price"
                                    data-title="Price"
                                    style={
                                      {
                                        // backgroundColor: isDisable && "#A4A9A9",
                                      }
                                    }
                                  >
                                    <p className="text-brand">
                                      $
                                      {(
                                        item.item.sales_price * item.quantity
                                      ).toFixed(2)}{" "}
                                    </p>
                                  </td>
                                </div>
                                <div
                                  className="col-8"
                                  style={{
                                    filter: !isBlur ? "blur(2px)" : "",
                                    pointerEvents: !isBlur ? "none" : "",
                                  }}
                                >
                                  <td
                                    className="price"
                                    data-title="Grand Total"
                                    style={
                                      {
                                        // backgroundColor: isDisable && "#A4A9A9",
                                      }
                                    }
                                  >
                                    <h4 className="text-brand text-center">
                                      ${totalAmount.toFixed(2)}{" "}
                                    </h4>
                                  </td>
                                </div>

                                <div className="col-4">
                                  <td className="action delete-cart">
                                    {/* {item.item.stock > 0 ? (
                                    <span className="stock-status in-stock mb-0">
                                      {" "}
                                      In Stock{" "}
                                    </span>
                                  ) : (
                                    <span className="stock-status out-stock mb-0">
                                      {" "}
                                      Stock Out{" "}
                                    </span>
                                  )} */}
                                    <p
                                      // onClick={(e) => handleRemoveItem(e, item)}
                                      onClick={(e) => {
                                        setdeleteItem(item);
                                        setdeletePopup(true);
                                      }}
                                      className="text-body"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <i className="fi-rs-trash" />
                                    </p>
                                  </td>
                                </div>
                              </div>
                            </tr>
                            <tr
                              key={index}
                              className="pt-30 mobile-cart cart-table-lg"
                            >
                              {/* <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id={"cart-ckb-" + index}
                          defaultValue=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"cart-ckb-" + index}
                        />
                      </td> */}
                              <td
                                className="image product-thumbnail pt-40"
                                id={`disableIdd${index}`}
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <Link
                                  style={{ marginLeft: "5px" }}
                                  className="m-2"
                                  to={
                                    "/product/" +
                                    item.item_id +
                                    "/" +
                                    convertToSlug(item.item.item_name) +
                                    "/" +
                                    true
                                  }
                                  title={item.item.item_name}
                                  state={{ isItem: true }}
                                >
                                  <img
                                    width={90}
                                    height={90}
                                    src={
                                      item?.image
                                        ? process.env.REACT_APP_BASE_URL +
                                          "/storage/products/" +
                                          item.image
                                        : process.env.REACT_APP_BASE_URL +
                                          "/storage/products/no-image.png"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </td>
                              <td
                                className="product-des product-name cart-product-name-wc"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <h6 className="mb-5">
                                  <Link
                                    className="product-name mb-10 text-heading"
                                    to={
                                      "/product/" +
                                      item.item_id +
                                      "/" +
                                      convertToSlug(item.item.item_name) +
                                      "/" +
                                      true
                                    }
                                    title={titleName}
                                    state={{ isItem: true }}
                                  >
                                    {titleName}
                                  </Link>
                                </h6>
                                <div className="product-rate-cover">
                                  <div className="product-rate d-inline-block">
                                    <div
                                      className="product-rating"
                                      style={{ width: "90%" }}
                                    ></div>
                                  </div>
                                  <span className="font-small ml-5 text-muted">
                                    {" "}
                                    (4.0)
                                  </span>
                                  <ul className="">
                                    {item.item?.brand?.brand_name && (
                                      <li className=" ">
                                        Brand :{" "}
                                        <span className="text-brand mr-3">
                                          {item.item?.brand?.brand_name}
                                        </span>
                                      </li>
                                    )}
                                    {item?.item?.product_code && (
                                      <li>
                                        Product Code :{" "}
                                        <span className="text-brand mr-3">
                                          {" "}
                                          {item?.item?.product_code}
                                        </span>
                                      </li>
                                    )}
                                    {item.item?.category?.category_name && (
                                      <li className=" ">
                                        Category :{" "}
                                        <span className="text-brand mr-3">
                                          {item.item?.category?.category_name}
                                        </span>
                                      </li>
                                    )}

                                    {item?.item?.color?.title && (
                                      <li className=" ">
                                        Color :{" "}
                                        <span className="text-brand mr-3">
                                          {" "}
                                          {item?.item?.color?.title}
                                        </span>
                                      </li>
                                    )}
                                    {item?.item?.size?.title && (
                                      <li>
                                        Size :{" "}
                                        <span className="text-brand mr-3">
                                          {" "}
                                          {item?.item?.size?.title}
                                        </span>
                                      </li>
                                    )}
                                    {/* <li className="">
                                    Stock :
                                    <span className="in-stock text-brand ml-5">
                                      {" "}
                                      {item?.item?.stock} Items In Stock
                                    </span>
                                  </li> */}
                                    {item?.item?.flavor?.title && (
                                      <li>
                                        Flavor :{" "}
                                        <span className="text-brand">
                                          {" "}
                                          {item?.item?.flavor?.title}
                                        </span>
                                      </li>
                                    )}
                                    {item.item?.unit?.unit_name && (
                                      <li className=" ">
                                        Unit :{" "}
                                        <span className="text-brand mr-3">
                                          {item.item?.unit?.unit_name}
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                                {isDisable && (
                                  <>
                                    <div
                                      className="alert alert-danger mt-3"
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        // flexDirection:'column',
                                        alignItems: "center",
                                        gap: "6px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <i className="fi-rs-cross-circle ml-3" />

                                      <Blink
                                        color="red"
                                        text={outOfStockMessage}
                                        fontSize="50"
                                      >
                                        {outOfStockMessage}
                                      </Blink>
                                    </div>
                                  </>
                                )}

                                {qtyLimtMsg?.isLimitExceeded && (
                                  <div
                                    className="alert alert-danger mt-3"
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      // flexDirection:'column',
                                      alignItems: "center",
                                      gap: "6px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <i className="fi-rs-cross-circle ml-3" />

                                    <Blink
                                      color="red"
                                      text={qtyLimtMsg?.message}
                                      fontSize="50"
                                    >
                                      {qtyLimtMsg?.message}
                                    </Blink>
                                  </div>
                                )}
                              </td>
                              <td
                                className="text-center pt-5"
                                // data-title="Stock"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                }}
                              >
                                {/* {item.item.stock > 0 ? (
                                <span className="stock-status in-stock mb-0">
                                  {" "}
                                  In Stock{" "}
                                </span>
                              ) : (
                                <span className="stock-status out-stock mb-0">
                                  {" "}
                                  Stock Out{" "}
                                </span>
                              )} */}
                              </td>
                              <td
                                className="price"
                                data-title="Price"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <h6 className="text-body">
                                  ${item.item?.sales_price?.toFixed(2)}
                                </h6>
                                <span className="user-id-font">
                                  <span> {userId}</span>
                                </span>
                              </td>
                              <td
                                className="text-center detail-info"
                                data-title="Stock"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <div className="detail-extralink mr-15">
                                  <div className="detail-qty border radius">
                                    <a
                                      href=""
                                      disabled={cartQuantityChange}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (item.quantity > 1) {
                                          decreaseQuantity(item.id, index);
                                        } else {
                                          setdeleteItem(item);
                                          setdeletePopup(true);
                                        }
                                      }}
                                      className="qty-down"
                                    >
                                      <i className="fi-rs-angle-small-down" />
                                    </a>
                                    {/* <span className="qty-val">{item.quantity}</span> */}
                                    <input
                                      className="qty-val"
                                      type="number"
                                      min={0}
                                      disabled={cartQuantityChange}
                                      style={{
                                        border: "none",
                                        maxHeight: "28px",
                                        color:
                                          cartQuantityChange &&
                                          "var(--hover-color)",
                                      }}
                                      value={item?.quantity}
                                      onChange={(e) => {
                                        if (
                                          typeof (
                                            Number(e?.target?.value) ===
                                            "number"
                                          ) ||
                                          e?.target?.value === ""
                                        ) {
                                          let countQty =
                                            e?.target?.value === ""
                                              ? 0
                                              : e?.target?.value;
                                          if (countQty > 0) {
                                            modifiedItemQty(
                                              item?.id,
                                              index,
                                              countQty,
                                              item?.item?.stock
                                            );
                                          } else {
                                            setdeleteItem(item);
                                            setdeletePopup(true);
                                          }
                                        }
                                      }}
                                    />

                                    <a
                                      href=""
                                      disabled={cartQuantityChange}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        increaseQuantity(
                                          item.id,
                                          index,
                                          item.quantity,
                                          item.item?.stock,
                                          item.item?.purchase_limit
                                        );
                                      }}
                                      className="qty-up"
                                    >
                                      <i className="fi-rs-angle-small-up" />
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td
                                className="price"
                                data-title="Price"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <b className="text-brand">
                                  $
                                  {(
                                    item.item.sales_price * item.quantity
                                  ).toFixed(2)}{" "}
                                </b>
                              </td>
                              <td
                                className="price"
                                data-title="Price"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "3px",
                                    justifyContent:
                                      !items[index]?.item?.taxValue && "center",
                                  }}
                                >
                                  {items[index]?.item?.taxValue
                                    ?.tax_product_amount ? (
                                    <p className="text-brand">
                                      $
                                      {(
                                        items[index]?.item?.taxValue
                                          ?.tax_product_amount * item.quantity
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    <p className="text-brand">NA</p>
                                  )}
                                  {items[index]?.item?.taxValue && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          {items[index]?.item?.taxValue ? (
                                            <div className="product-rate-cover">
                                              {/* cart-dropdown-hm2 account-dropdown h-50 */}
                                              <ul>
                                                {items[index]?.item?.taxValue
                                                  ?.name && (
                                                  <li className="mr-3">
                                                    <span
                                                      className="text-brand"
                                                      style={{
                                                        minWidth: "65px",
                                                        maxWidth: "80px",
                                                      }}
                                                    >
                                                      Tax Name :
                                                    </span>
                                                    <span>
                                                      {
                                                        items[index]?.item
                                                          ?.taxValue?.name
                                                      }
                                                    </span>
                                                  </li>
                                                )}
                                                {items[index]?.item?.taxValue
                                                  ?.price_type && (
                                                  <li className="mr-3">
                                                    <span
                                                      className="text-brand"
                                                      style={{
                                                        minWidth: "65px",
                                                        maxWidth: "80px",
                                                      }}
                                                    >
                                                      Tax type :
                                                    </span>
                                                    <span>
                                                      {" "}
                                                      {
                                                        items[index]?.item
                                                          ?.taxValue?.price_type
                                                      }
                                                    </span>
                                                  </li>
                                                )}
                                                {items[index]?.item?.taxValue
                                                  ?.tax_product_amount && (
                                                  <li className="mr-3">
                                                    <span
                                                      className="text-brand"
                                                      style={{
                                                        minWidth: "65px",
                                                        maxWidth: "80px",
                                                      }}
                                                    >
                                                      Tax Amount :
                                                    </span>
                                                    <span>
                                                      {" "}
                                                      {items[
                                                        index
                                                      ]?.item?.taxValue?.tax_product_amount.toFixed(
                                                        2
                                                      )}
                                                    </span>
                                                  </li>
                                                )}
                                                {items[index]?.item?.taxValue
                                                  ?.tax_unit && (
                                                  <li className="mr-3">
                                                    <span
                                                      className="text-brand"
                                                      style={{
                                                        minWidth: "65px",
                                                        maxWidth: "80px",
                                                      }}
                                                    >
                                                      Tax Unit :
                                                    </span>
                                                    <span>
                                                      {" "}
                                                      {
                                                        items[index]?.item
                                                          ?.taxValue?.tax_unit
                                                      }
                                                    </span>
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                          ) : (
                                            <span>NA</span>
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      <i
                                        className="fi-rs-info "
                                        style={{ paddingTop: "5px" }}
                                      />
                                    </OverlayTrigger>
                                  )}
                                </div>
                              </td>
                              <td
                                className="price"
                                style={{
                                  filter: !isBlur ? "blur(2px)" : "",
                                  pointerEvents: !isBlur ? "none" : "",
                                  // backgroundColor: isDisable && "#A4A9A9",
                                }}
                              >
                                <h4 className="text-brand text-center">
                                  ${totalAmount?.toFixed(2)}
                                </h4>
                              </td>
                              <td
                                className="action text-center"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <p
                                  href=""
                                  // onClick={(e) => handleRemoveItem(e, item)}
                                  onClick={(e) => {
                                    setdeleteItem(item);
                                    setdeletePopup(true);
                                  }}
                                  className="text-body"
                                >
                                  <i className="fi-rs-trash" />
                                </p>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={7}>
                          {" "}
                          <h5> No Items Available in Cart </h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="divider-2 mb-30" />

              <h6
                className="text-brand mb-2 curser-hover pe-auto"
                onClick={() => {
                  setisAddressPopup(true);
                }}
              >
                <a
                  href=""
                  onClick={(e) => {
                    e?.preventDefault();
                  }}
                >
                  {/* Change Address? */}
                </a>
              </h6>
              <div
                className="cart-action  d-flex gap-3 mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <a
                  // onClick={(e) => handleRemoveAll(e)}
                  onClick={() => setpopUpmodal(true)}
                  className={
                    "btn btn-sm" + (items.length == 0 ? " disabled" : "")
                  }
                >
                  <i
                    className="fi-rs-trash mr-10"
                    style={{ backgroundColor: "black" }}
                  />
                  Empty Cart
                </a>
                <a className="btn btn-sm" onClick={() => navigate("/")}>
                  {/* <i className="fi-rs-arrow-left mr-10" /> */}
                  Continue Shopping
                </a>
              </div>
              <div className="row mt-50 d-none">
                <div className="col-lg-7">
                  <div className="calculate-shiping p-40 border-radius-15 border">
                    <h4 className="mb-10">Calculate Shipping</h4>
                    <p className="mb-30">
                      <span className="font-lg text-muted">Flat rate:</span>
                      <strong className="text-brand">5%</strong>
                    </p>
                    <form className="field_form shipping_calculator">
                      <div className="form-row">
                        <div className="form-group col-lg-12">
                          <div className="custom_select">
                            <select
                              className="form-control select-active w-100 select2-hidden-accessible"
                              data-select2-id={7}
                              tabIndex={-1}
                              aria-hidden="true"
                            >
                              <option value="" data-select2-id={9}>
                                United Kingdom{" "}
                              </option>
                            </select>
                            <span
                              className="select2 select2-container select2-container--default"
                              dir="ltr"
                              data-select2-id={8}
                              style={{ width: "520.094px" }}
                            >
                              <span className="selection">
                                <span
                                  className="select2-selection select2-selection--single"
                                  role="combobox"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex={0}
                                  aria-labelledby="select2-n4i4-container"
                                >
                                  <span
                                    className="select2-selection__rendered"
                                    id="select2-n4i4-container"
                                    role="textbox"
                                    aria-readonly="true"
                                    title="United Kingdom"
                                  >
                                    United Kingdom
                                  </span>
                                  <span
                                    className="select2-selection__arrow"
                                    role="presentation"
                                  >
                                    <b role="presentation" />
                                  </span>
                                </span>
                              </span>
                              <span
                                className="dropdown-wrapper"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-row row">
                        <div className="form-group col-lg-6">
                          <input
                            required="required"
                            placeholder="State / Country"
                            name="name"
                            type="text"
                          />
                        </div>
                        <div className="form-group col-lg-6">
                          <input
                            required="required"
                            placeholder="ZIP"
                            name="name"
                            type="text"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="p-40">
                    <h4 className="mb-10">Apply Coupon</h4>
                    <p className="mb-30">
                      <span className="font-lg text-muted">
                        Using A Promo Code?
                      </span>
                    </p>
                    <form action="#">
                      <div className="d-flex justify-content-between">
                        <input
                          className="font-medium mr-15 coupon"
                          name="Coupon"
                          placeholder="Enter Your Coupon"
                        />
                        <button className="btn">
                          <i className="fi-rs-label mr-10" />
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="border p-md-4 cart-totals ml-12">
                <div className="table-responsive">
                  <table className="table no-border">
                    <tbody>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Subtotal</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">${subtotal}</h4>
                        </td>
                      </tr>

                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Tax</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">
                            ${totalTaxAmount || 0}
                          </h4>
                        </td>
                      </tr>
                      {/* <tr>
                  <td scope="col" colSpan={2}>
                    <div className="divider-2 mt-10 mb-10" />
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="text-muted">Shipping</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">Free </h5>
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="text-muted">Estimate for</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">United Kingdom </h5>
                  </td>
                </tr> */}
                      <tr>
                        <td scope="col" colSpan={2}>
                          <div className="divider-2 mt-10 mb-10" />
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Grand total</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">${total}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p
                  // to="/checkout"
                  onClick={() => {
                    if (
                      !isOutOfStockItemAvalaible ||
                      total < 199.99 ||
                      isNotAvailable
                    ) {
                      setproceedWithCartPopup(true);
                    }
                  }}
                  className={
                    "btn mb-20 w-100" +
                    (total < 199.99 ||
                    isOutOfStockItemAvalaible ||
                    isNotAvailable
                      ? " disabled"
                      : "")
                  }
                >
                  Proceed To CheckOut
                  <i className="fi-rs-sign-out ml-15" />
                </p>
              </div>
            </div>
            <ConfirmationPopupModal
              title={"Confirmation Popup"}
              content={"Are you Sure you want to Empty your cart ?"}
              show={popUpmodal}
              setShow={setpopUpmodal}
              confirmClicked={(e) => handleRemoveAll(e)}
            />
            <ConfirmationPopupModal
              title={"Confirmation Popup"}
              content={"Are you Sure you want to Proceed with your order ?"}
              show={proceedWithCartPopup}
              setShow={setproceedWithCartPopup}
              confirmClicked={() => {
                setproceedWithCartPopup(false);
                navigate(`/checkout/${addressIdFinal}`);
              }}
            />
            <ConfirmationPopupModal
              title={"Confirmation Popup"}
              content={`Are you Sure you want to Delete ${decodeHTMLEntities(
                deleteItem?.item?.item_name
              )} from cart ?`}
              show={deletePopup}
              setShow={setdeletePopup}
              confirmClicked={(e) => {
                // setdeletePopup(false);
                handleRemoveItem(e, deleteItem);
              }}
            />

            {/* {addressDetails.addresses?.length && (
            <ConfirmationPopupModal
              title={
                "Your Address is selected as default address ,if you want to change it you can change"
              }
              // content={`Your Address is selected as default address ,if you want to change it you can change`}
              show={isAddressPopup}
              addressData={addressDetails}
              setShow={setisAddressPopup}
              isAddress={true}
              addressId={addressId}
              addre
              setaddressId={setaddressId}
              onClose={onCloseAddressPopup}
              confirmClicked={(e) => {
                updateAddressValue();
              }}
              addressNum={addressNum}
              setaddressNum={setaddressNum}
            />
          )} */}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </main>
  );
};

export default Cart;
