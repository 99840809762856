import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  retriveOrders,
  retriveSingleOrders,
} from "../../services/OrderService";
import { stateByIds } from "../../services/StateService";
import {
  convertToSlug,
  decodeHTMLEntities,
  selectedPaymentType,
} from "../../utils/constant";
import Navigation from "./Navigation";
import { getAddressDetailsAPI } from "../../services/CartService";
import { getPosPaymentTypes } from "../../services/CustomerService";
import Loader from "../Loader";
import _ from "lodash";

const Orders = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [list, setlist] = useState([]);
  // const [orders, setOrders] = useState([]);
  const [singleOrder, setSingleOrder] = useState({});
  const [orderStates, setOrderStates] = useState([]);
  const [addressId, setaddressId] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [paymentType, setPaymentType] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [paginationLoading, setpaginationLoading] = useState(false);
  const [links, setLinks] = useState([]);

  const navigate = useNavigate();
  let date = "";
  useEffect(() => {
    window.scrollTo(0, 0);
    setPage(1);
    setPerPage(10);
    getAddressDetails();
    getPosPaymentType();
  }, []);

  useEffect(() => {
    if (addressId) {
      getOrders(addressId);
      setpaginationLoading(true);
    }
  }, [page, perPage]);

  useEffect(async () => {
    if (singleOrder?.orderData?.billing_address) {
      let ids = [
        singleOrder?.orderData?.billing_address.state_id,
        singleOrder?.orderData?.shipping_address.state_id,
      ].join(",");
      await stateByIds(ids)
        .then((response) => {
          if (response?.data?.data) setOrderStates(response.data.data);
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    }
  }, [singleOrder]);

  const getPosPaymentType = async () => {
    await getPosPaymentTypes()
      .then((response) => {
        setPaymentType(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const getAddressDetails = () => {
    getAddressDetailsAPI()
      .then((response) => {
        setaddressId(response?.data?.data[0]?.state_id);
        getOrders(response?.data?.data[0]?.state_id);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getOrders = async (stateId) => {
    let id = stateId || addressId;
    await retriveOrders(id, page, perPage)
      .then((response) => {
        setlist(response?.data?.data?.data);
        setLinks(response?.data?.data?.links);
        setisLoading(false);
        setpaginationLoading(false);
        // if (response?.data?.data) {
        // getTotalTax(response.data.data);
        // }
      })
      .catch((error) => {
        setpaginationLoading(false);
        console.log(error, "<error");
        if (error?.response?.status === 422)
          toast(error.response.data.error || "Something went wrong", {
            type: "error",
          });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const getSingleOrder = async (id) => {
    await retriveSingleOrders(id)
      .then((response) => {
        setSingleOrder(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const viewClicked = (order) => {
    setShowOrder(true);
    setisLoading(true);
    getSingleOrder(order?.id);
  };

  // const getTotalTax = (data) => {
  //   let mainArray = [];
  //   for (let a of data) {
  //     let mainObj = a;
  //     let finalPrice = 0;
  //     let salesPrice = 0;
  //     let totalTax = 0;
  //     let subTotal = 0;
  //     for (let b of a?.line_items) {
  //       let taxOfLineItems = b?.tax || 0;

  //       // finalPrice = finalPrice + b?.item?.detail?.final_price * b?.quantity;
  //       // salesPrice = salesPrice + b?.item?.detail?.sales_price * b?.quantity;
  //       salesPrice = salesPrice + (b?.price - taxOfLineItems) * b?.quantity;
  //       finalPrice = subTotal + b?.subtotal;

  //       subTotal = subTotal + (b?.price - taxOfLineItems) * b?.quantity;
  //       if (b?.tax) {
  //         totalTax = totalTax + b?.tax * b?.quantity;
  //       }
  //     }
  //     mainArray.push({
  //       ...mainObj,
  //       finalPrice: finalPrice.toFixed(2),
  //       salesPrice: salesPrice.toFixed(2),
  //       subTotal: subTotal.toFixed(2),
  //       totalTax: totalTax.toFixed(2),
  //     });
  //   }
  //   setOrders(mainArray);
  //   setisLoading(false);

  //   // let obj = [tax, salesPrice, finalPrice];
  //   // return 0;
  // };


  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Orders
          </div>
        </div>
      </div>

      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content">
                    <div
                      className="tab-pane fade active show"
                      id="orders"
                      role="tabpanel"
                      aria-labelledby="orders-tab"
                    >
                      {!isLoading ? (
                        <>
                          {!showOrder ? (
                            <div
                              className="card"
                              style={{ minHeight: "650px" }}
                            >
                              <div className="card-header">
                                <h3 className="mb-0">Your Orders</h3>
                              </div>
                              {!paginationLoading ? (
                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead className="dashboard-order">
                                        <tr>
                                          <th>Order</th>
                                          <th>Date</th>
                                          <th>Status</th>
                                          <th>Fulfillment Status</th>
                                          <th>Sub Total</th>
                                          <th>Tax</th>
                                          <th>Grand Total</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {list.map((order, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              className="dashboard-order-list"
                                            >
                                              <td>#order-{order?.id}</td>
                                              <td>
                                                {(() => {
                                                  date = new Date(
                                                    order.created_at
                                                  );
                                                  if (
                                                    date != null &&
                                                    date != "Invalid Date"
                                                  ) {
                                                    return (
                                                      date.toLocaleString(
                                                        "default",
                                                        {
                                                          month: "long",
                                                        }
                                                      ) +
                                                      " " +
                                                      date.getDate() +
                                                      ", " +
                                                      date.getFullYear()
                                                    );
                                                  }
                                                })()}
                                              </td>
                                              <td>{order?.order_status}</td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {order.fulfillment_status ==
                                                null
                                                  ? "Not Fulfilled"
                                                  : order.fulfillment_status ==
                                                    "partial"
                                                  ? order.fulfillment_status
                                                  : order.fulfillment_status}
                                              </td>
                                              <td>
                                                ${order?.subTotal?.toFixed(2)}
                                              </td>
                                              <td>${order?.tax?.toFixed(2)}</td>

                                              <td>
                                                ${order?.grandTotal?.toFixed(2)}
                                              </td>
                                              <td>
                                                <a
                                                  href=""
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    viewClicked(order);
                                                  }}
                                                  className="btn-small d-block"
                                                >
                                                  View
                                                </a>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  {!_.isEmpty(list) ? (
                                    <div className="pagination-area mt-20 mb-20 d-flex justify-content-center">
                                      <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-start">
                                          {links?.map((link, index) => {
                                            return (
                                              <li
                                                key={index}
                                                className={
                                                  "page-item?" +
                                                  (link.active
                                                    ? " page-link-active"
                                                    : "")
                                                }
                                              >
                                                <a
                                                  className={
                                                    "page-link page-arrow page-link-active"
                                                  }
                                                  style={{
                                                    backgroundColor: link.active
                                                      ? "#E57D26"
                                                      : "",
                                                  }}
                                                  href=""
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    if (link.url) {
                                                      setPage(
                                                        link.url?.split(
                                                          "page="
                                                        )[1]
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {link?.label
                                                    .replace(
                                                      /&laquo; Previous/g,
                                                      "<<"
                                                    )
                                                    .replace(
                                                      /Next &raquo;/g,
                                                      ">>"
                                                    )}
                                                </a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </nav>
                                    </div>
                                  ) : (
                                    <div style={{textAlign:'center'}}>

                                      No Data Avalaible
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <Loader />
                              )}
                            </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h2 className="woocommerce-order-details__title">
                                  Order details
                                </h2>
                                <div
                                  style={{
                                    display: "flex",
                                    padding: "4px",
                                    // justifyContent: "end",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    onClick={() => {
                                      navigate(
                                        `/my-account/orders/${singleOrder?.orderData?.id}/${addressId}`
                                      );
                                    }}
                                  >
                                    {" "}
                                    View
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                      setSingleOrder([]);
                                      setShowOrder(false);
                                    }}
                                  >
                                    {" "}
                                    Go Back
                                  </button>
                                </div>
                              </div>

                              <section className="woocommerce-order-details">
                                <div
                                  style={{
                                    // display: "flex",
                                    // justifyContent: "space-between",
                                    paddingBottom: "5px",
                                  }}
                                >
                                  <p style={{ paddingTop: "10px" }}>
                                    Order #
                                    <mark className="order-number">
                                      order-{singleOrder?.orderData?.id}
                                    </mark>{" "}
                                    was placed on{" "}
                                    <mark className="order-date">
                                      {
                                        (date =
                                          new Date(
                                            singleOrder?.orderData?.created_at
                                          ).toLocaleString("default", {
                                            month: "long",
                                          }) +
                                          " " +
                                          new Date(
                                            singleOrder?.orderData?.created_at
                                          ).getDate() +
                                          " " +
                                          new Date(
                                            singleOrder?.orderData?.created_at
                                          ).getFullYear())
                                      }
                                    </mark>{" "}
                                    and currently status is{" "}
                                    <mark className="order-status">
                                      {singleOrder?.orderData?.order_status}
                                    </mark>
                                    .
                                  </p>
                                </div>

                                <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                                  <thead>
                                    <tr>
                                      <th className="woocommerce-table__product-name product-name">
                                        Product
                                      </th>
                                      <th className="woocommerce-table__product-name product-name">
                                        quantity
                                      </th>
                                      <th className="woocommerce-table__product-name product-name">
                                        Status
                                      </th>
                                      <th className="woocommerce-table__product-table product-total">
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {singleOrder?.list?.map((item, index) => {
                                      // let subtotalByItem =
                                      //   item?.quantity *
                                      //   item?.item?.detail?.sales_price;
                                      return (
                                        <tr
                                          key={index}
                                          className="woocommerce-table__line-item order_item"
                                        >
                                          <td className="woocommerce-table__product-name product-name">
                                            <Link
                                              to={
                                                "/product/" +
                                                item?.itemDetail?.id +
                                                "/" +
                                                convertToSlug(
                                                  item?.itemDetail?.item_name
                                                ) +
                                                "/" +
                                                "true"
                                              }
                                            >
                                              {decodeHTMLEntities(
                                                item?.itemDetail?.item_name
                                              )}
                                            </Link>{" "}
                                            {/* <strong className="product-quantity">
                                                ×&nbsp;{item.quantity}
                                              </strong>{" "} */}
                                          </td>
                                          <td className="woocommerce-table__product-name product-name">
                                            <strong className="product-quantity">
                                              &nbsp;{item?.quantity}
                                            </strong>{" "}
                                          </td>
                                          <td className="woocommerce-table__product-name product-name">
                                            {/* {item?.status == null
                                              ? "Pending"
                                              : item?.status} */}
                                            {item?.type}
                                          </td>
                                          <td className="woocommerce-table__product-total product-total">
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  $
                                                </span>
                                                {item?.total?.toFixed(2)}
                                              </bdi>
                                            </span>{" "}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Subtotal:
                                      </th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {singleOrder?.orderData?.subTotal?.toFixed(
                                            2
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Tax:
                                      </th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {singleOrder?.orderData?.tax?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                      <th scope="row">Shipping:</th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">$</span>5.00
                                        </span>
                                        &nbsp;<small className="shipped_via">via Flat rate</small>
                                      </td>
                                    </tr> */}
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Payment method:
                                      </th>
                                      <td>
                                        {selectedPaymentType(
                                          paymentType,
                                          singleOrder?.orderData?.payment_type
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row" colSpan={3}>
                                        Grand Total:
                                      </th>
                                      <td>
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {singleOrder?.orderData?.grandTotal?.toFixed(2)}
                                        </span>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </section>
                              <section className="woocommerce-customer-details">
                                <section className="row">
                                  <div className="col">
                                    <h5 className="woocommerce-column__title">
                                      Billing address
                                    </h5>
                                    <address>
                                      {
                                        singleOrder?.orderData?.billing_address
                                          ?.name
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.billing_address
                                          ?.company_name
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.billing_address
                                          ?.address
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.billing_address
                                          ?.city
                                      }
                                      <br />
                                      {
                                        orderStates[
                                          singleOrder?.orderData
                                            ?.billing_address?.state_id
                                        ]
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.billing_address
                                          ?.pincode
                                      }

                                      <p className="woocommerce-customer-details--phone">
                                        {
                                          singleOrder?.orderData
                                            ?.billing_address?.mobile
                                        }
                                      </p>
                                      <p className="woocommerce-customer-details--email">
                                        {
                                          singleOrder?.orderData
                                            ?.billing_address?.email
                                        }
                                      </p>
                                    </address>
                                  </div>
                                  {/* /.col-1 */}
                                  <div className="col">
                                    <h5 className="woocommerce-column__title">
                                      Shipping address
                                    </h5>
                                    <address>
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.name
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.company_name
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.address
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.city
                                      }
                                      <br />
                                      {
                                        orderStates[
                                          singleOrder?.orderData
                                            ?.shipping_address.state_id
                                        ]
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.pincode
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.mobile
                                      }
                                      <br />
                                      {
                                        singleOrder?.orderData?.shipping_address
                                          ?.email
                                      }
                                    </address>
                                  </div>
                                  {/* /.col-2 */}
                                </section>
                                {/* /.col2-set */}
                              </section>
                            </>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Orders;
