import React from "react";

const MessageBox = () => {
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    lineHeight: "1.6",
    fontSize: "16px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  };

  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    marginBottom: "15px",
  };

  const contactStyle = {
    fontWeight: "bold",
  };

  const addressStyle = {
    fontWeight: "bold",
    marginTop: "20px",
  };

  return (
    <div style={containerStyle}>
      <p style={paragraphStyle}>Dear Valued Customer,</p>
      <p style={paragraphStyle}>
        We are excited to announce the launch of our brand new website, designed
        to enhance your experience with improved features and a fresh, modern
        look.
      </p>
      <p style={headingStyle}>Action Required:</p>
      <p style={paragraphStyle}>
        To continue accessing your account and all our services, please contact
        our customer support team during normal business hours Monday to Friday
        9:00am to 5:30pm. They will assist you in setting up your access to the
        new platform.
      </p>
      <p style={paragraphStyle}>
        You can reach us at <span style={contactStyle}>(630) 785-6838</span> or
        you can send us your contact information via Email at{" "}
        <a style={contactStyle} href="mailto:info@silverlinewholesale.com">
          info@silverlinewholesale.com
        </a>{" "}
        or text message at <span style={contactStyle}>(630) 866-6838</span> and
        our customer support team will contact you.
      </p>
      <p style={paragraphStyle}>
        We appreciate your cooperation and look forward to providing you with an
        even better online experience.
      </p>

      <span style={contactStyle}>
        If you have already created a new account with us, close this and
        proceed to login.
      </span>
      <p style={paragraphStyle}>Thank you for your continued trust in us.</p>

      <p style={paragraphStyle}>Best regards,</p>
      <p style={addressStyle}>
        Silverline Wholesale, Inc.
        <br />
        1442 Norwood Ave
        <br />
        Itasca, IL 60143
      </p>
    </div>
  );
};

export default MessageBox;
