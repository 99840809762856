import { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addItemToCart,
  addSelectedItemToCart,
  retriveItemByIds,
  wishlistClearApi,
  wishlistCount,
} from "../../services/ItemService";
import { AppContext } from "../../utils/AppProvider";
import {
  convertToSlug,
  decodeHTMLEntities,
  isLogin,
} from "../../utils/constant";
import ConfirmationPopupModal from "../ConfirmationPopupModal";
import Loader from "../Loader";
import { userId } from "../../layout/Index";

const Wishlist = () => {
  const [wishlistItems, setwishlistItems] = useState([]);
  const [selectedItems, setSelectedItem] = useState([]);
  const [removeSelectedItems, setremoveSelectedItems] = useState(false);
  const [addSelectedItems, setaddSelectedItems] = useState(false);
  const [addToCartPopup, setaddToCartPopup] = useState(false);
  const [addToCartItem, setaddToCartItem] = useState({});
  const [deletePopup, setdeletePopup] = useState(false);
  const [allDataToCartPopup, setallDataToCartPopup] = useState(false);
  const [tempItem, settempItem] = useState({});
  const [emptyWishlistPopup, setemptyWishlistPopup] = useState(false);
  const [isNotAvailable, setisNotAvailable] = useState(false);
  const { wishlist_qty, setWishlistQty, setCartQty, cart_qty } =
    useContext(AppContext);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    getWishlist();
    getWishlistCount();
  }, []);

  const getWishlistCount = async () => {
    await wishlistCount()
      .then((response) => {
        setWishlistQty(response?.data?.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const getWishlist = async () => {
    // let items = await localStorage.getItem("wishlist_items");

    retriveItemByIds()
      .then((response) => {
        setisLoading(false);
        let array = [];
        for (let a of response?.data?.data?.groups) {
          array?.push({
            ...a,
            isGroup: true,
            item_name: a?.title,
            item_image: a?.groups_image || a?.new_groups_image,
          });
        }
        for (let a of response?.data?.data?.items) {
          if (!a?.showOnWeb || !a?.status) {
            setisNotAvailable(true);
          }
        }
        setwishlistItems([...response?.data?.data?.items, ...array]);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const clearWishlist = async (cart) => {
    if (wishlistItems.length) {
      // await localStorage.removeItem("wishlist_items");

      let formdata = new FormData();

      formdata.append("id", "all");

      await wishlistClearApi(formdata)
        .then((response) => {
          // setWishlistQty(response?.data?.data);
          getWishlist();
          getWishlistCount();
        })
        .catch((error) => {
          console.log("Error:", error);
        });
      setwishlistItems([]);
      setSelectedItem([]);
      setemptyWishlistPopup(false);
      setremoveSelectedItems(false);
      if (!cart) {
        toast("Favourites clear successfully", { type: "success" });
      }
    }
  };

  const toggleWishlist = async (item, e) => {
    e.preventDefault();
    let formdata = new FormData();

    formdata.append("id", item?.id);
    await wishlistClearApi(formdata)
      .then((response) => {
        // setWishlistQty(response?.data?.data);
        getWishlist();
        getWishlistCount();
        toast("Item Deleted Successfully.", {
          type: "success",
        });
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        console.log("Error:", error);
      });
    setdeletePopup(false);
  };

  const handleSelectItemAll = (e, item) => {
    if (e.target.checked) {
      setSelectedItem(item);
    } else {
      setSelectedItem([]);
    }
  };

  const handleSelectItem = (e, item) => {
    if (e.target.checked) {
      setSelectedItem([...selectedItems, item]);
    } else {
      setSelectedItem(selectedItems.filter((i) => i.id != item.id));
    }
  };

  const clearSelected = async () => {
    let groupArray = [];
    let itemArray = [];
    for (let a of selectedItems) {
      if (a?.isGroup) {
        groupArray?.push(a?.id);
      } else {
        itemArray?.push(a?.id);
      }
    }

    let formdata = new FormData();
    let selectedItemIds = "";
    let selectedGroupIds = "";
    if (groupArray.length > 0) {
      selectedGroupIds = groupArray?.join(",");
    }
    if (itemArray.length > 0) {
      selectedItemIds = itemArray?.join(",");
    }
    formdata.append("id", selectedItemIds);
    formdata.append("group_id", selectedGroupIds);

    await wishlistClearApi(formdata)
      .then((response) => {
        // setWishlistQty(response?.data?.data);
        getWishlist();
        getWishlistCount();
        toast("Item Deleted Successfully.", {
          type: "success",
        });
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
        console.log("Error:", error);
      });
    setSelectedItem([]);

    setremoveSelectedItems(false);
  };

  const handleAddToCart = (item) => {
    if (isLogin()) {
      let item_id = item.id;
      addItemToCart(item_id, 1, true)
        .then((response) => {
          // let items = wishlistItems.filter((i) => i.id != item_id);

          // setwishlistItems(items);
          // setWishlistQty(wishlist_qty - 1);
          setCartQty(parseInt(cart_qty) + 1);
          setaddToCartItem({});
          setaddToCartPopup(false);

          toast("Item Added To Cart Successfully.", { type: "success" });
        })
        .catch((error) => {
          console.log("Error", error);
          if (error?.response?.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const addSelectedToCart = () => {
    let ids = [];

     for (let a of selectedItems) {
       if (!a?.isGroup) {
         ids?.push(a?.id);
       }
     }
    if (ids != "") {
      if (isLogin()) {
        addSelectedItemToCart(ids)
          .then((response) => {
            // clearSelected();
            setSelectedItem([]);

            setCartQty(parseInt(cart_qty) + ids.length);
            toast("Item Added To Cart Successfully.", { type: "success" });
          })
          .catch((error) => {
            console.log("Error", error);
            if (error?.response?.status == 422)
              toast(error.response.data.message, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      } else {
        toast("Please login to add item to cart", { type: "error" });
      }
    } else {
      toast("Please Select item", { type: "error" });
    }
  };

  const addAllItemToCart = () => {
      let ids = [];

      for (let a of wishlistItems) {
        if (!a?.isGroup) {
          ids?.push(a?.id);
        }
      }
    if (ids != "") {
      if (isLogin()) {
        addSelectedItemToCart(ids)
          .then((response) => {
            setCartQty(parseInt(cart_qty) + ids.length);
            setSelectedItem([]);

            // clearWishlist(true);
            setallDataToCartPopup(false);
            toast("Item Added To Cart Successfully.", { type: "success" });
          })
          .catch((error) => {
            console.log("Error", error);
            if (error?.response?.status == 422)
              toast(error.response.data.message, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      } else {
        toast("Please login to add item to cart", { type: "error" });
      }
    } else {
      toast("There are no items in your favorites.", { type: "error" });
            setallDataToCartPopup(false);

    }
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Favourites
          </div>
        </div>
      </div>
      <div className="container mb-30 mt-50">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="mb-30">
              <h1 className="heading-2 mb-10">Your Favourites</h1>
            </div>
            {isNotAvailable && (
              <div
                className="alert alert-danger mt-3 "
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "6px",
                  marginRight: "5px",
                }}
                role="alert"
              >
                <i className="fi-rs-cross-circle ml-3" />
                Blur items are not currently accessible on the website.
              </div>
            )}
            {!isLoading ? (
              <div
                className="table-responsive shopping-summery"
                style={{
                  height: "585px",
                  backgroundColor: "var(--dark-container)",
                }}
              >
                <table className="table table-wishlist">
                  <thead>
                    <tr
                      className="main-heading"
                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                    >
                      <th className="custome-checkbox start pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox11"
                          defaultValue=""
                          checked={
                            selectedItems.length == wishlistItems.length
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleSelectItemAll(e, wishlistItems)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheckbox11"
                        />
                      </th>
                      <th scope="col" colSpan={2}>
                        Product
                      </th>
                      <th scope="col">Price</th>
                      <th scope="col">Stock Status</th>
                      <th scope="col">Action</th>
                      <th scope="col" className="end">
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="d-lg-none d-md-none mobile-w-c">
                      <td className="custome-checkbox start pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox11"
                          defaultValue=""
                          checked={
                            selectedItems.length == wishlistItems.length
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleSelectItemAll(e, wishlistItems)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheckbox11"
                          style={{ float: "left", marginLeft: "-10px" }}
                        >
                          All
                        </label>
                      </td>
                    </tr>
                    {wishlistItems.length > 0 ? (
                      wishlistItems?.map((item, index) => {
                        let e = document?.getElementById(`disableId${index}`);
                        e?.addEventListener("contextmenu", (ev) => {
                          ev?.preventDefault(); // this will prevent browser default behavior
                        });
                        let x = document?.getElementById(
                          `disableIdMobileView${index}`
                        );
                        x?.addEventListener("contextmenu", (ev) => {
                          ev?.preventDefault(); // this will prevent browser default behavior
                        });
                        let isBlur = true;
                        let imgURL = item?.groups_image
                          ? "/storage/group_images/"
                          : "/storage/products/";

                        if (!item?.isGroup) {
                          isBlur = item?.showOnWeb && item?.status;
                        }

                        return (
                          <>
                            <tr
                              key={index + 1}
                              className="d-lg-none d-md-none mobile-w-c pt-30"
                              style={{
                                filter: !isBlur ? "blur(2px)" : "",
                                pointerEvents: !isBlur ? "none" : "",
                              }}
                            >
                              <div className="row">
                                <div className="col-6">
                                  <td
                                    className="image product-thumbnail pt-40"
                                    id={`disableIdMobileView${index}`}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="checkbox"
                                      id={"item-ckbx-" + index}
                                      defaultValue=""
                                      checked={selectedItems
                                        ?.map((i) => {
                                          return i.id;
                                        })
                                        ?.includes(item.id)}
                                      onChange={(e) =>
                                        handleSelectItem(e, item)
                                      }
                                      style={{
                                        float: "left",
                                        margin: "0 15px 10px 0",
                                      }}
                                    />
                                    <Link
                                      to={
                                        "/product/" +
                                        item.id +
                                        "/" +
                                        convertToSlug(item.item_name) +
                                        "/" +
                                        !item?.isGroup
                                      }
                                    >
                                      <img
                                        width={90}
                                        height={90}
                                        src={
                                          item?.item_image
                                            ? process.env.REACT_APP_BASE_URL +
                                              imgURL +
                                              item.item_image
                                            : process.env.REACT_APP_BASE_URL +
                                              "/storage/products/no-image.png"
                                        }
                                        className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                        alt=""
                                        loading="lazy"
                                      />
                                    </Link>
                                  </td>
                                </div>
                                <div className="col-6 align-items-center justify-content-center d-flex">
                                  <td className="product-des product-name">
                                    <h6>
                                      <Link
                                        to={
                                          "/product/" +
                                          item.id +
                                          "/" +
                                          convertToSlug(item.item_name) +
                                          "/" +
                                          !item?.isGroup
                                        }
                                      >
                                        {decodeHTMLEntities(item.item_name)}
                                      </Link>
                                    </h6>
                                    <div className="product-rate-cover">
                                      <div className="product-rate d-inline-block">
                                        <div
                                          className="product-rating"
                                          style={{ width: "90%" }}
                                        />
                                      </div>
                                      <span className="font-small ml-5 text-muted">
                                        {" "}
                                        (4.0)
                                      </span>
                                    </div>
                                  </td>
                                </div>
                                {!item?.isGroup && (
                                  <div className="col-6">
                                    <td className="price" data-title="Price">
                                      <h3 className="text-brand">
                                        ${item.sales_price.toFixed(2)}
                                      </h3>
                                      <span className="user-id-font">
                                        <span> {userId}</span>
                                      </span>
                                    </td>
                                  </div>
                                )}
                                {!item?.isGroup && (
                                  <div className="col-6">
                                    <td
                                      className="text-center detail-info"
                                      data-title="Stock"
                                    >
                                      {item.stock > 0 ? (
                                        <span className="stock-status in-stock mb-0">
                                          {" "}
                                          In Stock{" "}
                                        </span>
                                      ) : (
                                        <span className="stock-status out-stock mb-0">
                                          {" "}
                                          In Stock{" "}
                                        </span>
                                      )}
                                    </td>
                                  </div>
                                )}
                                {!item?.isGroup && (
                                  <div className="col-6">
                                    <td className="text-right">
                                      <button
                                        onClick={() => handleAddToCart(item)}
                                        className="btn btn-sm"
                                      >
                                        Add to cart
                                      </button>
                                    </td>
                                  </div>
                                )}
                                <div className="col-6">
                                  <td className="action text-center  whishlist-padding">
                                    <p
                                      onClick={() => {
                                        setdeletePopup(true);
                                        settempItem(item);
                                      }}
                                      className="text-body"
                                    >
                                      <i className="fi-rs-trash" />
                                    </p>
                                  </td>
                                </div>
                              </div>
                            </tr>

                            <tr
                              key={index}
                              className="pt-30 mobile-whishlist cart-table-lg"
                              style={{
                                filter: !isBlur ? "blur(2px)" : "",
                                pointerEvents: !isBlur ? "none" : "",
                              }}
                            >
                              <td className="custome-checkbox pl-30">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkbox"
                                  id={"item-ckbx-" + index}
                                  defaultValue=""
                                  checked={selectedItems
                                    ?.map((i) => {
                                      return i.id;
                                    })
                                    ?.includes(item.id)}
                                  onChange={(e) => handleSelectItem(e, item)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"item-ckbx-" + index}
                                />
                              </td>
                              <td
                                className="image product-thumbnail pt-40"
                                id={`disableId${index}`}
                              >
                                <Link
                                  to={
                                    "/product/" +
                                    item.id +
                                    "/" +
                                    convertToSlug(item.item_name) +
                                    "/" +
                                    !item?.isGroup
                                  }
                                >
                                  <img
                                    width={90}
                                    height={90}
                                    src={
                                      item?.item_image
                                        ? process.env.REACT_APP_BASE_URL +
                                          imgURL +
                                          item.item_image
                                        : process.env.REACT_APP_BASE_URL +
                                          "/storage/products/no-image.png"
                                    }
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt=""
                                    loading="lazy"
                                  />
                                </Link>
                              </td>
                              <td className="product-des product-name whishlist-product-name-wc">
                                <h6>
                                  <Link
                                    to={
                                      "/product/" +
                                      item.id +
                                      "/" +
                                      convertToSlug(item.item_name) +
                                      "/" +
                                      !item?.isGroup
                                    }
                                  >
                                    {decodeHTMLEntities(item.item_name)}
                                  </Link>
                                </h6>
                                <div className="product-rate-cover">
                                  <div className="product-rate d-inline-block">
                                    <div
                                      className="product-rating"
                                      style={{ width: "90%" }}
                                    />
                                  </div>
                                  <span className="font-small ml-5 text-muted">
                                    {" "}
                                    (4.0)
                                  </span>
                                </div>
                              </td>
                              <td className="price" data-title="Price">
                                {!item?.isGroup && (
                                  <>
                                    <h3 className="text-brand">
                                      ${item.sales_price.toFixed(2)}
                                    </h3>
                                    <span className="user-id-font">
                                      <span> {userId}</span>
                                    </span>
                                  </>
                                )}
                              </td>
                              <td
                                className="text-center detail-info"
                                data-title="Stock"
                              >
                                {!item?.isGroup && (
                                  <>
                                    {item.stock > 0 ? (
                                      <span className="stock-status in-stock mb-0">
                                        {" "}
                                        In Stock{" "}
                                      </span>
                                    ) : (
                                      <span className="stock-status out-stock mb-0">
                                        {" "}
                                        Stock Out{" "}
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="text-right" data-title="Cart">
                                {!item?.isGroup && (
                                  <>
                                    <button
                                      onClick={() => {
                                        setaddToCartItem(item);
                                        setaddToCartPopup(true);
                                      }}
                                      className="btn btn-sm"
                                    >
                                      Add to cart
                                    </button>
                                  </>
                                )}
                              </td>
                              <td className="action text-center">
                                <p
                                  onClick={() => {
                                    setdeletePopup(true);
                                    settempItem(item);
                                  }}
                                  className="text-body"
                                >
                                  <i className="fi-rs-trash" />
                                </p>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={8}>
                          {" "}
                          <h6> No Items Available </h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <Loader />
            )}
            <div style={{ float: "right", paddingTop: "20px" }}>
              <button
                type="button"
                className="button btn-sm"
                disabled={wishlistItems.length <= 0}
                style={{ opacity: wishlistItems.length <= 0 ? "0.7" : "1" }}
                name="tinvwl-action-product_selected"
                value="product_selected"
                onClick={() => {
                  setremoveSelectedItems(true);
                }}
              >
                Remove Selected Items
              </button>
              <button
                type="button"
                className="button btn-sm m-2"
                disabled={wishlistItems.length <= 0}
                style={{ opacity: wishlistItems.length <= 0 ? "0.7" : "1" }}
                name="tinvwl-action-product_selected"
                value="product_selected"
                onClick={() => setemptyWishlistPopup(true)}
              >
                Empty Wishlist
              </button>
              <button
                className="button btn-sm"
                onClick={() => {
                  setaddSelectedItems(true);
                }}
                disabled={wishlistItems.length <= 0}
                style={{ opacity: wishlistItems.length <= 0 ? "0.7" : "1" }}
                type="button"
                name="tinvwl-action-product_selected"
                value="product_selected"
              >
                Add Selected to Cart
              </button>
              <button
                className="button btn-sm m-2"
                onClick={() => {
                  setallDataToCartPopup(true);
                }}
                disabled={wishlistItems.length <= 0}
                style={{ opacity: wishlistItems.length <= 0 ? "0.7" : "1" }}
                type="button"
                name="tinvwl-action-product_all"
                value="product_all"
              >
                Add All to Cart
              </button>
            </div>
          </div>

          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to Delete ${decodeHTMLEntities(
              tempItem?.item_name
            )} from wishlist ?`}
            show={deletePopup}
            setShow={setdeletePopup}
            confirmClicked={(e) => {
              toggleWishlist(tempItem, e);
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to Empty your wishlist ?`}
            show={emptyWishlistPopup}
            setShow={setemptyWishlistPopup}
            confirmClicked={(e) => {
              clearWishlist();
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to add all item to cart ?`}
            show={allDataToCartPopup}
            setShow={setallDataToCartPopup}
            confirmClicked={(e) => {
              addAllItemToCart();
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to remove selected items from the wishlist ?`}
            show={removeSelectedItems}
            setShow={setremoveSelectedItems}
            confirmClicked={(e) => {
              clearSelected();
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to add selected items to the cart ?`}
            show={addSelectedItems}
            setShow={setaddSelectedItems}
            confirmClicked={(e) => {
              addSelectedToCart();
              setaddSelectedItems(false);
            }}
          />
          <ConfirmationPopupModal
            title={"Confirmation Popup"}
            content={`Are you Sure you want to add ${decodeHTMLEntities(
              addToCartItem?.item_name
            )}  to the cart ?`}
            show={addToCartPopup}
            setShow={setaddToCartPopup}
            confirmClicked={(e) => {
              handleAddToCart(addToCartItem);
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default Wishlist;
